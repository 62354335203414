/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import contentLibraryService from "../../_services/staff/contentLibrary.service";
import notFound from "../images/notfound.png";
import Pagination from "../../shared/Pagination";
import { LIMIT } from "../constants/constants";
import { courseUserStatusService } from "../../_services/staff/courseUserStatus.service";
import { FaCircleCheck } from "react-icons/fa6";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

const defaultUserImage = "/assets/images/college-project.png";

class ContentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      courseCategoryId: this.props?.match?.params?.courseCategoryId || "",
      contentCategories: [],
      loading: true,
      page: 1,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    console.log(" this.props: ", this.props);
    userService
      .getUserById(currentUser?.id)
      .then((data) => {
        data.Data && this.props.setUserData(data.Data);
      })
      .catch((error) => console.log("Error", error));

    this.getAllContentCategories();
    if (window.Websdk) {
      window.Websdk.q.push(["track_pageview", "content-library"]);
    }
  }

  updateCourseStatus(data) {
    if (data.status === "TODO") {
      const payload = {
        id: data?.courseStatusId,
        updateData: {
          status: "INPROGRESS",
        },
        courseId: data?._id,
        userId: this.state.currentUser.id,
        courseCategoryId: this.state.courseCategoryId,
      };
      courseUserStatusService
        .updateCourseStatus(payload)
        .then((res) => {
          this.props.history.push(
            !data?.isLocked
              ? `/course-category/${this.state.courseCategoryId}/category-detail/${data._id}`
              : `/course-category/${this.state.courseCategoryId}`,
          );
        })
        .catch((error) => console.log("Error", error));
    }
  }

  // eslint-disable-next-line react/sort-comp
  getAllContentCategories() {
    if (this.props?.match?.params?.courseCategoryId) {
      contentLibraryService
        .getAllCourses(this.state.currentUser.id, this.state.courseCategoryId)
        .then((data) => {
          const rowsArray = [];
          data &&
            data.Data &&
            data.Data.forEach((item) => {
              rowsArray.push({
                _id: item._id,
                category: item.name,
                description: item.description,
                location: item.location,
                isLocked: item?.isLocked !== undefined ? item?.isLocked : true,
                // status: item?.isCompleted ? "Completed" : "Pending",
                status: item?.result?.status || "",
                courseStatusId: item?.result?._id || "",
                view: (
                  <Link
                    to={`/course-category/${this.state.courseCategoryId}/category-detail/${item._id}`}
                    className="btn btn-sm btn-purple"
                  >
                    <span id={item._id} className="hide-menu">
                      View Files
                    </span>
                  </Link>
                ),
                index: item.index,
                clickEvent: () => document.getElementById(item._id).click(),
              });
            });

          const tableData = {
            columns: [
              // {
              //   label: "No.",
              //   field: "key",
              //   sort: "asc",
              //   width: 270,
              // },
              {
                label: "Course",
                field: "category",
                sort: "asc",
                width: 150,
              },
              {
                label: "Description",
                field: "description",
                sort: "asc",
                width: 150,
              },
              {
                label: "View",
                field: "view",
                sort: "disabled",
                width: 270,
              },
            ],
            rows: rowsArray,
          };
          this.setState({
            contentCategories: tableData,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("Error", error);
        });
    } else {
      this.setState({
        loading: false,
      });
      swal("Course category not provided", "", "error");
    }
  }

  handlePageClick(e) {
    this.setState({ page: e.selected + 1 });
  }

  handleImageError = (event) => {
    event.target.src = defaultUserImage;
  };

  render() {
    const { contentCategories } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        <div className="page-wrapper contentLibrary d-flex justify-content-center align-items-center">
          {this.state.loading ? (
            <Preloader />
          ) : (
            <>
              <div className="container-fluid">
                <div className="row page-titles">
                  <div className="col-md-5 col-8 align-self-center">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item cursor-pointer"
                        onClick={() => this.props.history.push("/")}
                      >
                        Home
                      </li>
                      <li
                        className="breadcrumb-item cursor-pointer"
                        onClick={() =>
                          this.props.history.push(`/course-categories`)
                        }
                      >
                        Categories
                      </li>
                      <li className="breadcrumb-item active">Courses</li>
                    </ol>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="mb-4">
                        <Link
                          to="/course-categories"
                          className="btn btn-purple"
                        >
                          <i className="mdi mdi-chevron-left" />
                          <span className="hide-menu">Back</span>
                        </Link>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          {/* <div className="d-flex justify-content-between"> */}
                          <div className="d-flex align-items-center tbl-head">
                            <i className="mdi mdi-view-dashboard mr-2" />
                            <h2 className="mb-0">Courses</h2>
                          </div>
                          {/* </div> */}
                          <hr />
                          <div className="row">
                            {contentCategories?.rows &&
                            contentCategories?.rows?.length !== 0 ? (
                              contentCategories?.rows?.map((item, index) => {
                                const { page } = this.state;
                                const start = (page - 1) * LIMIT;
                                const end = page * LIMIT;
                                return (
                                  <div
                                    className="col-xl-2 col-lg-3 col-md-4 col-sm-4"
                                    onClick={() => {
                                      if (item?.status === "TODO") {
                                        this.updateCourseStatus(item);
                                      } else {
                                        this.props.history.push(
                                          !item?.isLocked
                                            ? `/course-category/${this.state.courseCategoryId}/category-detail/${item._id}`
                                            : `/course-category/${this.state.courseCategoryId}`,
                                        );
                                      }
                                    }}
                                  >
                                    <div>
                                      <div className="resource-library-card rounded mb-4">
                                        <div className="card-image">
                                          <img
                                            src={
                                              item?.location
                                                ? item?.location
                                                : defaultUserImage
                                            }
                                            alt="Card Image"
                                            onError={this.handleImageError}
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              width: "100%",
                                              height: "250px",
                                            }}
                                          />
                                        </div>
                                        <div className="resource-library-card-body">
                                          <div className="card-top">
                                            <div
                                              className="document-content"
                                              role="button"
                                              tabIndex="0"
                                            >
                                              <h3 className="text-truncate">
                                                {item.category}
                                              </h3>
                                              <div className="course-description rounded pb-3">
                                                <p>{item.description}</p>
                                              </div>
                                            </div>
                                          </div>

                                          {!item?.isLocked ? (
                                            <div className="category-resume">
                                              <div>{item?.status}</div>
                                              {item?.status !== "COMPLETED" ? (
                                                <img
                                                  src="/images/right-arrow.png"
                                                  alt="aero"
                                                />
                                              ) : (
                                                <FaCircleCheck
                                                  color="#47a15f"
                                                  size={25}
                                                />
                                              )}
                                            </div>
                                          ) : (
                                            <div className="category-resume">
                                              <div>To Do</div>
                                              <img
                                                src="/images/lock.png"
                                                alt="lock"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div
                                className="d-flex flex-column justify-content-center align-items-center no-data w-100"
                                style={{
                                  minHeight: "20vh",
                                }}
                              >
                                <div className="text p-4">
                                  Thanks for Registering and for Verifying your
                                  account! You will be able to access the
                                  Training Modules within 24 hours.
                                </div>
                              </div>
                            )}
                          </div>
                          {contentCategories?.rows &&
                          contentCategories?.rows?.length !== 0 ? (
                            <Pagination
                              totalCount={contentCategories?.rows?.length}
                              currentPage={this.state.page - 1}
                              handlePageClick={this.handlePageClick}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterAdmin />
            </>
          )}
          {/* <HeaderAdmin />
        <Sidebaar /> */}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ContentLibrary));
