import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

function mapStateToProps() {
  return {};
}

class Sidebaar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li>
                <NavLink
                  to="/dashboard"
                  className="waves-effect waves-dark"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7460EE" : "",
                      fontWeight: isActive ? 500 : "",
                    };
                  }}
                >
                  <i className="mdi mdi-view-dashboard" />
                  <span className="hide-menu">Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/course-categories"
                  className="waves-effect waves-dark"
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7460EE" : "",
                      fontWeight: isActive ? 500 : "",
                    };
                  }}
                >
                  <i className="mdi mdi-view-dashboard" />{" "}
                  <span className="hide-menu">Categories</span>
                </NavLink>
              </li>

              {/* <li>
                <a href="https://ayework.ca/" target="_blank" rel="noreferrer">
                  <img
                    className="blink_me"
                    src="https://ayework.ca/wp-content/uploads/2020/03/logo-1.png"
                    alt="AyeWork"
                  />
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(Sidebaar);
