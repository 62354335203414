import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

class FooterAdmin extends Component {
    render() {
        return (
            <footer className="footer footer-color">
            <span> © 2023 WEST of Windsor Inc. </span>
            <span>Powered By YQG Technologies Inc. </span>
         </footer>
        );
    }
}

export default connect(
    mapStateToProps,
)(FooterAdmin);