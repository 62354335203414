import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";

function getAll() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories`,
    requestOptions,
  ).then(handleResponse);
}

function getAllCourses(userId, courseCategoryId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-courses/${userId}/${courseCategoryId}`,
    requestOptions,
  ).then(handleResponse);
}

function getContentLibaryCategroryById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function create(formData) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUser.token}`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"), 10)
        : 0,
    },
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/create`,
    requestOptions,
  ).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/create`,
    requestOptions,
  ).then(handleResponse);
}

function deleteContentCategory(categoryId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ categoryId, currentUserId }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/delete`,
    requestOptions,
  ).then(handleResponse);
}

function getFilesByCategoryId(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/getFiles/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function getFileDetailsById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/getFileDetails/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function getFileStatusById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/getFileStatus/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function getFilesByCategoryIdAndUserId(id, userId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/getFilesByUserId/${id}/${userId}`,
    requestOptions,
  ).then(handleResponse);
}

function uploadFileToContentLibrary(id, data) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUser.token}`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"), 10)
        : 0,
    },
    body: data,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/upload/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function getSignedLinkFromKey(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-url`,
    requestOptions,
  ).then(handleResponse);
}

function deleteFile(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/delete-file`,
    requestOptions,
  ).then(handleResponse);
}

function resourcefeedback(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/feedback`,
    requestOptions,
  ).then(handleResponse);
}

function flagAsViewed(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/flag-viewed`,
    requestOptions,
  ).then(handleResponse);
}

function getAnswerCount(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-answer-count`,
    requestOptions,
  ).then(handleResponse);
}

const contentLibraryService = {
  getAll,
  getContentLibaryCategroryById,
  create,
  update,
  deleteContentCategory,
  getFilesByCategoryId,
  uploadFileToContentLibrary,
  getSignedLinkFromKey,
  deleteFile,
  resourcefeedback,
  flagAsViewed,
  getAllCourses,
  getFilesByCategoryIdAndUserId,
  getFileDetailsById,
  getAnswerCount,
  getFileStatusById,
};

export default contentLibraryService;
