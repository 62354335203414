import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";

function getAllCourseCategories() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-category`,
    requestOptions,
  ).then(handleResponse);
}

const courseCategoryService = {
  getAllCourseCategories,
};

export default courseCategoryService;
