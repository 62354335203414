/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";

import { authenticationService } from "../_services";
import { Role } from "../_helpers";
import Layout from "./Layout";

function PrivateRoute({ component: Component, roles, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
        if (roles && roles.role !== currentUser.role) {
          return currentUser.role === Role.User ? (
            <Redirect to={{ pathname: "/" }} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

export default PrivateRoute;
