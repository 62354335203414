import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const notificationService = {
  getNotifications,
  updateStatus,
};

function getNotifications(userId, status = "") {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/notification?userId=${userId}&${
      status ? `status=${status}` : ""
    }`,
    requestOptions,
  ).then(handleResponse);
}

function updateStatus(data) {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/notification`,
    requestOptions,
  ).then(handleResponse);
}
