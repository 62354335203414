import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preloader from '../../components/Preloader';
import HeaderAdmin from '../../components/HeaderAdmin';
import SidebarSuperAdmin from '../../components/admin/Sidebaar';
import Sidebar from '../../components/user/Sidebaar';
import FooterAdmin from '../../components/FooterAdmin';
import {Form, Formik, Field, ErrorMessage} from 'formik';
import {setUserData} from '../../redux-store/action';
import { Link } from 'react-router-dom';
import { authenticationService } from '../../_services';
import { departmentService,userService } from '../../_services/admin';
import { NotificationManager } from 'react-notifications';
import * as Yup from 'yup';
import PasswordMask from 'react-password-mask';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData:(data)=>{dispatch(setUserData(data))}
    };
}

function validateEmail(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

class changePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            user:{},
            department: [],
            loading: true
        };
    }

   
    componentDidMount() {
        let scripts = [
            '/assets/js/custom.min.js'
        ];
        let host = window.location.origin;
        console.log("host", host);
        scripts.map((t) => {
            let script = document.createElement("script");
            script.src = host + t;
            script.async = true;
            document.body.appendChild(script);
            return true;
        });
        this.getAllDepartments();
        this.props.setUserData(this.state.currentUser);
            this.props.match.params.id && userService.getUserById(this.props.match.params.id)
            .then(data =>{ 
                this.setState({user: data.Data, loading: false});
            });
            this.setState({loading: false});
    }
    getAllDepartments() {
        departmentService.getAll()
        .then(data => this.setState({department: data.Data}))
    }

    render() {
        const departments = this.state.department;

        return (
            <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
            {this.state.loading && <Preloader />}
            <HeaderAdmin />
            <Sidebar />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row page-titles">
                        <div className="col-md-5 col-8 align-self-center">
                            <h3 className="text-themecolor m-b-0 m-t-0">Change Password</h3>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Change Password</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row mt-4row justify-content-center mt-5">
                        <div className="col-md-6">
                            <div className="card">
                            <Formik enableReinitialize={true}
                                initialValues={{
                                   currentpassword:'',
                                    newpassword: '',
                                    confirmpassword: '',
                                    currentUserId:this.state.currentUser.id
                                   
                                }}
                                validationSchema={Yup.object().shape({
                                    currentpassword: Yup.string().required('Current password is required'),
                                    // newpassword: Yup.string().required('New password is required'),
                                    newpassword:  Yup.string().matches(
                                                /^(?=.*[a-z])(?=.*[A-Z])/,
                                                'Must have both uppercase and lowercase letters'
                                              ).matches(
                                                /^(?=.*[0-9])/,
                                                'Must consist of a number'
                                              ).matches(
                                                /^(?=.{8,})/,
                                                'Must be atleast 8 character long'
                                              ).required('Password is required').when("currentpassword", {
                                                  is:val => (val && val.length > 0 ? true : false),
                                                  then: Yup.string().notOneOf([Yup.ref("currentpassword")], 
                                                  "Current Password and New Password cannot be same"
                                                  )
                                              }),
                                    // confirmpassword: Yup.string().required('Confirm password is required'),
                                    confirmpassword:Yup.string().required('Confirm password is required').when("newpassword", {
                                        is: val => (val && val.length > 0 ? true : false),
                                        then: Yup.string().oneOf(
                                          [Yup.ref("newpassword")],
                                          "Both password need to be the same"
                                        )
                                      })
                                   
                                })}
                                
                                onSubmit={(formData, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    userService.changePassword(formData)
                                        .then(
                                            user => {
                                                console.log('user: ', user);
                                                NotificationManager.success(user.Data.Message,"", 5000);
                                                const { from } = this.props.location.state || { from: { pathname: "/" } };
                                                this.props.history.push(from);
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error);
                                            }
                                        );
                                }}
                                render={({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <div className="card-body pb-0">
                                        <div className="row form-material">
                                            <div className="col-md-12">
                                            <div className="form-group required">
                                                    <label className="control-label">Current Password</label>
                                                    <Field name="currentpassword" placeholder="currentpassword"
                                                        autoComplete="off"
                                                        render={({field})=>(
                                                            <PasswordMask
                                                            inputStyles={{border:'none'}}
                                                            
                                                            buttonStyles={{top:'5px',backgroundColor:'transparent'}}

                                                            showButtonContent={<i className="ti-eye" style={{color:'#ccc',backgroundColor:'transparent'}}></i> }
                                                            hideButtonContent={<i className="ti-eye" style={{backgroundColor:'transparent'}}></i> }
                                                            onChange={(password) => password === null ? setFieldValue('currentpassword', "") : setFieldValue('currentpassword', password)}
                                                                {...field}
                                                            className={'form-control' + (errors.currentpassword && touched.currentpassword ? ' is-invalid' : '')}
                                                                />
                                                         )}
                                                         />
                                                    <ErrorMessage name="currentpassword" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group required">
                                                    <label className="control-label">New Password</label>
                                                    <Field name="newpassword" placeholder="newpassword"
                                                        autoComplete="off"
                                                        render={({field})=>(
                                                            <PasswordMask
                                                            inputStyles={{border:'none'}}
                                                            
                                                            buttonStyles={{top:'5px',backgroundColor:'transparent'}}

                                                            showButtonContent={<i className="ti-eye" style={{color:'#ccc',backgroundColor:'transparent'}}></i> }
                                                            hideButtonContent={<i className="ti-eye" style={{backgroundColor:'transparent'}}></i> }
                                                            onChange={(password) => password === null ? setFieldValue('newpassword', "") : setFieldValue('newpassword', password)}
                                                                {...field}
                                                            className={'form-control' + (errors.newpassword && touched.newpassword ? ' is-invalid' : '')}
                                                                />
                                                         )}
                                                         />
                                                    <ErrorMessage name="newpassword" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group required">
                                                    <label className="control-label">Confirm Password</label>
                                                    <Field name="confirmpassword" placeholder="confirmpassword"
                                                        autoComplete="off"
                                                        render={({field})=>(
                                                            <PasswordMask
                                                            inputStyles={{border:'none'}}
                                                            
                                                            buttonStyles={{top:'5px',backgroundColor:'transparent'}}

                                                            showButtonContent={<i className="ti-eye" style={{color:'#ccc',backgroundColor:'transparent'}}></i> }
                                                            hideButtonContent={<i className="ti-eye" style={{backgroundColor:'transparent'}}></i> }
                                                            onChange={(password) => password === null ? setFieldValue('confirmpassword', "") : setFieldValue('confirmpassword', password)}
                                                                {...field}
                                                            className={'form-control' + (errors.confirmpassword && touched.confirmpassword ? ' is-invalid' : '')}
                                                                />
                                                         )}
                                                         />
                                                        <ErrorMessage name="confirmpassword" component="div" className="invalid-feedback" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-0 accordionfooter text-right">
                                        <Link to="/" className="btn btn-outline-secondary mr-2">Cancel</Link>
                                        <button type="submit" disabled={isSubmitting} className="btn btn-purple"> Change Password </button>
                                        {isSubmitting &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                    </div>
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }
                                </Form>
                                )}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <FooterAdmin />
            </div>
        </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(changePassword);