/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  FormText,
  Col,
} from "reactstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import ReactPlayer from "react-player";
import ReactStars from "react-rating-stars-component";
import VideoThumbnail from "react-video-thumbnail";
import { Card, ProgressBar, Tab, Tabs } from "react-bootstrap";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
// import FeedbackCategory from "../../components/user/FeedbackCategory";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import contentLibraryService from "../../_services/staff/contentLibrary.service";
import { getFileExtension } from "../../_helpers/_helperFunctions";
import { BsPlayCircle, BsFileImage } from "react-icons/bs";
import { PiFilePptFill, PiImageSquareFill } from "react-icons/pi";
import { BiImage } from "react-icons/bi";
import { AiOutlineFileText, AiOutlineQuestionCircle } from "react-icons/ai";
import { MdOutlineAudioFile } from "react-icons/md";
import { FaCircleCheck, FaRegCircleCheck } from "react-icons/fa6";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { FcLock } from "react-icons/fc";
import { VscFilePdf } from "react-icons/vsc";

import {
  AUDIO_FILES,
  VIDEO_FILES,
  DOCS_AND_IMAGES,
  IMAGE_TYPES,
  DOCS_TYPES,
  PPT_TYPES,
} from "../../_helpers";
import { CustomModal } from "../../components/CustomModal";
import { fileUserStatusService } from "../../_services/staff/fileUserStatus.service";
import { FileViewer } from "react-file-viewer";
import { courseTypes } from "../constants/constants";
// const styles = {
//   minHeight: "400px",
//   minWidth: "700px",
// };

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function startFileWatchEvent() {
  if (window.Websdk) {
    window.Websdk.q.push(["start_event", "fileWatch"]);
  }
}

function stopFileWatchEvent(file, user) {
  if (window.Websdk) {
    window.Websdk.q.push([
      "end_event",
      {
        key: "fileWatch",
        segmentation: {
          fileId: file._id,
          fileName: file.originalname,
          fileTitle: file.title,
          userName: `${user?.firstName} ${user?.lastName}`,
          userId: user.id,
          userEmail: user.email,
        },
      },
    ]);
  }
}

function flagFileAsViewed(file, user) {
  if (file.fileView?.length === 0) {
    const flagFileData = {
      fileId: file._id,
      currentUserId: user.id,
    };
    contentLibraryService.flagAsViewed(flagFileData).then(() => {});
  }
}

function recordFileWatchEvent(file, user) {
  if (window.Websdk) {
    window.Websdk.q.push([
      "add_event",
      {
        key: "fileWatch",
        segmentation: {
          fileId: file._id,
          fileName: file.originalname,
          fileTitle: file.title,
          userName: `${user?.firstName} ${user?.lastName}`,
          userId: user.id,
          userEmail: user.email,
        },
      },
    ]);
  }
}

const defaultUserImage = "/assets/images/college-project.png";

class CategoryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      courseCategoryId: this.props?.match?.params?.courseCategoryId || "",
      loading: true,
      contentCategory: {},
      contentFiles: [],
      completedPercentage: 0,
      isModalOpen: false,
      contentFilesCopy: [],
      audioModal: false,
      videoModal: false,
      selectedFileType: "",
      feedbackModal: false,
      feedbackCategory: "",
      feedbackComment: "",
      allowFeedback: false,
      mediaUrl: "",
      filename: "",
      fileId: "",
      fileViewer: false,
      pptViewer: false,
      imageViewer: false,
      selectedFile: null,
      fileType: "",
      splitScreen: false,
      courseStatus: "",
    };

    this.toggleAddFileModal = this.toggleAddFileModal.bind(this);
    this.toggleFeedbackModal = this.toggleFeedbackModal.bind(this);
    this.handleFeedbackCategory = this.handleFeedbackCategory.bind(this);
    this.handleRatingChanged = this.handleRatingChanged.bind(this);
    this.handleFeedbackFormSubmit = this.handleFeedbackFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.getCategroryDetail();
    this.getFilesByCategoryId();
    // this.setState({ loading: false });
    const { id } = this.props.match.params;
    contentLibraryService
      .getAllCourses(this.state.currentUser.id, this.state.courseCategoryId)
      .then((data) => {
        const matchedCourse = data?.Data.find((course) => course._id === id);

        if (matchedCourse) {
          this.setState({
            courseStatus: matchedCourse?.result?.status,
          });
        }
      })
      .catch((error) => {
        // Handle any errors that may occur during the API call
        console.error("Error fetching data: ", error);
      });
  }

  handleFeedbackCategory(value) {
    this.setState({ feedbackCategory: value });
  }

  handleFeedbackFormSubmit() {
    const { hasFoundHelpful, feedbackComment, feedbackRatings } = this.state;

    if (!feedbackRatings) {
      NotificationManager.error("Feedback Ratings is required!", "", 5000);
      return null;
    }

    if (!hasFoundHelpful) {
      NotificationManager.error(
        "Please mention if whether or not you found resource to be helpful!",
        "",
        5000,
      );
      return null;
    }

    const formdata = {
      currentUserId: this.state.currentUser?.id,
      userName: `${this.state.currentUser?.firstName} ${this.state.currentUser?.lastName}`,
      userEmail: this.state.currentUser?.email,
      contentFileId: this.state.fileId,
      hasFoundHelpful,
      comment: feedbackComment,
      rating: feedbackRatings,
    };

    contentLibraryService.resourcefeedback(formdata).then(() => {
      this.toggleFeedbackModal();
      this.setState({
        feedbackCategory: "",
        feedbackComment: "",
        feedbackRatings: 0,
        hasFoundHelpful: "",
      });
      NotificationManager.success("Feedback submitted successfully!", "", 5000);
    });
  }

  handleRatingChanged(rating) {
    this.setState({ feedbackRatings: rating });
  }

  getIconForFileExtension(key) {
    if (key) {
      const fileExtension = getFileExtension(key);
      if (AUDIO_FILES.includes(fileExtension)) {
        return <MdOutlineAudioFile size={32} className="mr-4" color="orange" />;
      } else if (VIDEO_FILES.includes(fileExtension)) {
        return <BsPlayCircle size={32} className="mr-4" color="#1686ed" />;
      } else if (IMAGE_TYPES.includes(fileExtension)) {
        return <PiImageSquareFill size={32} className="mr-4" color="#7461ef" />;
      } else if (DOCS_TYPES.includes(fileExtension)) {
        return <VscFilePdf size={32} className="mr-4" color="#007bff" />;
      } else if (PPT_TYPES.includes(fileExtension)) {
        return <PiFilePptFill size={32} className="mr-4" color="#b94623" />;
      }
      return null;
    } else {
      return (
        <AiOutlineQuestionCircle
          size={32}
          className="mr-4"
          color="rgb(231 91 14)"
        />
      );
    } // You can set a default icon or perform some other action here
  }

  componentWillUnmount() {
    if (this.state.imageViewer || this.state.fileViewer || this.state.pptViewer) {
      stopFileWatchEvent(this.state.selectedFile, this.state.currentUser);
    }
  }

  getFileFromKey(key, filename, fileId, file) {
    const requestBody = { key, fileId };
    contentLibraryService.getSignedLinkFromKey(requestBody).then((data) => {
      const fileExtension = getFileExtension(key);

      if (this.state.imageViewer || this.state.fileViewer || this.state.pptViewer) {
        stopFileWatchEvent(this.state.selectedFile, this.state.currentUser);
      }

      if (AUDIO_FILES.includes(fileExtension)) {
        startFileWatchEvent();
        this.setState({
          mediaUrl: data.Data.url,
          filename,
          fileId,
          selectedFile: file,
          videoModal: false,
          fileViewer: false,
          pptViewer: false,
          imageViewer: false,
          splitScreen: false,
        });
        this.toggleAudio();
        return;
      } else if (VIDEO_FILES.includes(fileExtension)) {
        this.setState({
          splitScreen: true,
        });
        this.setState({
          mediaUrl: data.Data.url,
          filename,
          fileId,
          selectedFile: file,
          audioModal: false,
          fileViewer: false,
          pptViewer: false,
          imageViewer: false,
        });
        this.toggleVideo();
        return;
      } else if (DOCS_TYPES.includes(fileExtension)) {
        startFileWatchEvent();
        this.setState({
          mediaUrl: data.Data.url,
          filename,
          fileId,
          selectedFile: file,
          fileType: getFileExtension(key),
          selectedFileType: "file",
          audioModal: false,
          videoModal: false,
          imageViewer: false,
          splitScreen: true,
          pptViewer: false,
        });

        flagFileAsViewed(file, this.state.currentUser);
        window.open(data.Data.url, "_blank");
        this.togglefileViewer();
        return;
      } else if (PPT_TYPES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename,
          fileId,
          selectedFile: file,
          fileType: getFileExtension(key),
          selectedFileType: "file",
          audioModal: false,
          videoModal: false,
          imageViewer: false,
          fileViewer: false,
          // splitScreen: true,
        });

        flagFileAsViewed(file, this.state.currentUser);
        window.open(data.Data.url, "_blank");
        // this.togglePPTViewer();
        return;
      } else if (IMAGE_TYPES.includes(fileExtension)) {
        startFileWatchEvent();
        this.setState({
          splitScreen: true,
          mediaUrl: data.Data.url,
          filename,
          fileId,
          selectedFile: file,
          fileType: getFileExtension(key),
          selectedFileType: "file",
          audioModal: false,
          videoModal: false,
          fileViewer: false,
          pptViewer: false,
        });
        flagFileAsViewed(file, this.state.currentUser);
        // window.open(data.Data.url, "_blank");
        this.toggleImageViewer();
        return;
      } else {
        recordFileWatchEvent(file, this.state.currentUser);
        flagFileAsViewed(file, this.state.currentUser);
        window.open(data.Data.url, "_blank");
        return;
      }
    });
  }

  getFilesByCategoryId() {
    this.props.match.params.id &&
      contentLibraryService
        .getFilesByCategoryIdAndUserId(
          this.props.match.params.id,
          this.state.currentUser.id,
        )
        .then((data) => {
          let completed = 0;
          data.Data.forEach(
            (item) => item?.result?.status === "COMPLETED" && completed++,
          );
          this.setState({
            completedPercentage: (
              (completed * 100) /
              +data.Data.length
            ).toFixed(2),
            contentFiles: data.Data,
            loading: false,
            contentFilesCopy: data.Data,
          });
        })
        .catch(() => {
          this.setState({
            completedPercentage: 0,
            contentFiles: [],
            loading: false,
            contentFilesCopy: [],
          });
        });
  }

  getCategroryDetail() {
    this.props.match.params.id &&
      contentLibraryService
        .getContentLibaryCategroryById(this.props.match.params.id)
        .then((data) => {
          // track content library viewed
          if (window.Websdk) {
            window.Websdk.q.push(["track_pageview", data.Data.name]);
          }
          this.setState({ contentCategory: data.Data, loading: false });
        });
  }

  togglefileViewer = () => {
    this.setState((state) => {
      const setStateObj = { fileViewer: !state.fileViewer };
      if (state.fileViewer) {
        stopFileWatchEvent(state.selectedFile, state.currentUser);
        if (
          this.state.selectedFile.feedback &&
          !this.state.selectedFile.feedbackData.length
        ) {
          setStateObj.feedbackModal = true;
        }
        return setStateObj;
      }
      return setStateObj;
    });
  };

  // currently not in use
  togglePPTViewer = () => {
    this.setState((state) => {
      const setStateObj = { pptViewer: !state.pptViewer };
      if (state.pptViewer) {
        stopFileWatchEvent(state.selectedFile, state.currentUser);
        if (
          this.state.selectedFile.feedback &&
          !this.state.selectedFile.feedbackData.length
        ) {
          setStateObj.feedbackModal = true;
        }
        return setStateObj;
      }
      return setStateObj;
    });
  };

  toggleImageViewer = () => {
    this.setState((state) => {
      const setStateObj = { imageViewer: !state.imageViewer };
      if (state.imageViewer) {
        stopFileWatchEvent(state.imageViewer, state.currentUser);
        if (
          this.state.selectedFile.feedback &&
          !this.state.selectedFile.feedbackData.length
        ) {
          setStateObj.feedbackModal = true;
        }
        return setStateObj;
      }
      return setStateObj;
    });
  };

  toggleAudio = () => {
    // this.setState({
    //   audioModal: !(this.state.audioModal),
    //   selectedFileType: 'audio'
    // })

    this.setState((state) => {
      if (state.videoModal) {
        stopFileWatchEvent(state.selectedFile, state.currentUser);
      }
      return {
        audioModal: !state.audioModal,
        selectedFileType: "audio",
      };
    });
  };

  toggleVideo = () => {
    // this.setState({
    //   videoModal: !(this.state.videoModal),
    //   selectedFileType: 'video'
    // })
    this.setState((state) => {
      if (state.videoModal) {
        stopFileWatchEvent(state.selectedFile, state.currentUser);
      }
      return {
        videoModal: !state.videoModal,
        selectedFileType: "video",
      };
    });
  };

  toggleFeedbackModal = () => {
    if (
      this.state.selectedFile.feedback &&
      !this.state.selectedFile.feedbackData.length
    ) {
      this.setState({
        feedbackModal: !this.state.feedbackModal,
      });
    }
  };

  // deleteFile(fileId) {
  //   let requestBody = {
  //     fileId: fileId,
  //     categoryId: this.state.contentCategory._id,
  //     currentUserId: this.state.currentUser.id,
  //   };
  //   contentLibraryService.deleteFile(requestBody).then((data) => {
  //     this.getFilesByCategoryId();
  //     this.getCategroryDetail();
  //   });
  // }

  getConfig = (downloadOption) => {
    if (downloadOption) {
      return {};
    }
    return {
      file: {
        attributes: {
          controlslist: "nodownload",
        },
      },
    };
  };

  onChangeFileHandler = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };

  uploadFile = () => {
    if (this.state.contentCategory._id) {
      const data = new FormData();
      data.append("name", this.state.contentCategory.name);
      data.append("currentUserId", this.state.currentUser?.id);
      if (this.state.selectedFiles) {
        for (let x = 0; x < this.state.selectedFiles.length; x++) {
          data.append("files", this.state.selectedFiles[x]);
        }
      }
      contentLibraryService
        .uploadFileToContentLibrary(this.state.contentCategory._id, data)
        .then(() => {
          this.toggleAddFileModal();
          this.getFilesByCategoryId();
          this.getCategroryDetail();
        });
    } else {
      this.toggleAddFileModal();
    }
  };

  filterCard = (event) => {
    // this.setState({
    //   search: event.target.value
    // })
    if (event.target.value) {
      const filtered = this.state.contentFilesCopy.filter((value) => {
        return value?.title
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      this.setState({
        contentFiles: filtered,
      });
    } else {
      this.setState({
        contentFiles: this.state.contentFilesCopy,
      });
    }
  };

  handleFeedbackComment = (event) => {
    this.setState({ feedbackComment: event.target.value });
  };

  getThumbnail = (file) => {
    const fileExtension = file.originalname.split(".");

    if (VIDEO_FILES?.includes(fileExtension[1])) {
      return <VideoThumbnail videoUrl={file.location} />;
    }
    if (IMAGE_TYPES?.includes(fileExtension[1]?.toLowerCase())) {
      return (
        <Card.Img
          top
          width="100%"
          src={file.location}
          alt={file.title ? file.title : file.originalname}
          className="cursor-pointer cardRatio"
          onClick={() => {
            this.getFileFromKey(
              file.key,
              file.title || file.originalname,
              file._id,
              file,
            );
          }}
        />
      );
    }
    return (
      <FileIcon
        extension={fileExtension[1]}
        {...defaultStyles[fileExtension[1]]}
      />
    );
  };

  handleFoundHelpfulChanged = (e) => {
    this.setState({ hasFoundHelpful: e.target.value });
  };

  toggleAddFileModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  updateCourseFileStatus(data, status) {
    if (data?.result?.status === "TODO") {
      const payload = {
        id: data?.result?._id,
        updateData: {
          status,
        },
        index: data?.index,
        courseId: data?.category,
        userId: this.state?.currentUser?.id,
        courseIndex: this.state?.contentCategory?.index,
        courseCategoryId: this.state.courseCategoryId,
      };
      fileUserStatusService
        .updateFileStatus(payload)
        .then((data) => {
          this.getCategroryDetail();
          this.getFilesByCategoryId();
          this.setState({ loading: false });
        })
        .catch((error) => console.log("Error", error));
    }
  }

  deleteCategrory() {
    if (this.state.contentCategory._id) {
      contentLibraryService
        .deleteContentCategory(
          this.state.contentCategory._id,
          this.state.currentUser?.id,
        )
        .then(() => {
          const { from } = this.props.location.state || {
            from: { pathname: "/staff/contents-library" },
          };
          this.props.history.push(from);
        });
    }
  }

  render() {
    const { contentFiles, contentCategory } = this.state;

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* {this.state.loading ? <Preloader /> : ""} */}
        {/* <HeaderAdmin /> */}
        {/* <Sidebaar /> */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Course Detail</h3>
                <ol className="breadcrumb">
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() => this.props.history.push("/")}
                  >
                    Home
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/course-categories`)
                    }
                  >
                    Categories
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(
                        `/course-category/${this.state.courseCategoryId}`,
                      )
                    }
                  >
                    Courses
                  </li>
                  <li className="breadcrumb-item active">Course Detail</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* <div> */}
                {/* <div className=""> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="mb-4">
                          <Link
                            to={`/course-category/${this.state.courseCategoryId}`}
                            className="btn btn-purple"
                          >
                            <i className="mdi mdi-chevron-left" />
                            <span className="hide-menu">Back</span>
                          </Link>
                        </div>
                        <div className="d-flex">
                          {/* <div className="mr-2">
                                <input
                                  type="text"
                                  onChange={this.filterCard}
                                  className="form-control"
                                  placeholder="search file"
                                />
                                <i className="mdi mdi-magnify search-icon" />
                              </div> */}
                          {this.state.currentUser?.accessType === 2 && (
                            <div className="mr-2">
                              <button
                                type="button"
                                className="btn btn-purple"
                                onClick={() => this.toggleAddFileModal()}
                              >
                                <i className="mdi mdi-plus" />
                                Add Files
                              </button>

                              <Modal
                                isOpen={this.state.isModalOpen}
                                toggle={this.toggleAddFileModal}
                              >
                                <ModalHeader toggle={this.toggleAddFileModal}>
                                  Add Files
                                </ModalHeader>
                                <ModalBody>
                                  <Form>
                                    <FormGroup row>
                                      <Col sm={12}>
                                        <Input
                                          type="file"
                                          name="file"
                                          id="exampleFile"
                                          multiple
                                          onChange={this.onChangeFileHandler}
                                        />
                                        <FormText color="muted">
                                          Maximum 4 files allowed.
                                        </FormText>
                                      </Col>
                                    </FormGroup>
                                  </Form>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="primary"
                                    onClick={this.uploadFile}
                                  >
                                    Upload Files
                                  </Button>{" "}
                                  <Button
                                    color="secondary"
                                    onClick={this.toggleAddFileModal}
                                  >
                                    Cancel
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            </div>
                          )}
                          {this.state.currentUser?.accessType === 2 && (
                            <div>
                              <button
                                type="button"
                                className="btn btn-purple"
                                onClick={() => this.deleteCategrory()}
                              >
                                Delete Course
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex">
                        <div
                          className={`${
                            this.state.splitScreen ? "w-25" : "w-100"
                          }`}
                        >
                          {this.state.splitScreen ? (
                            <div className="d-flex flex-column align-items-center line-background mb-3">
                              <div className="mr-5">
                                <img
                                  src={
                                    contentCategory?.location
                                      ? defaultUserImage
                                      : defaultUserImage
                                  }
                                  width="150"
                                  height="150"
                                />
                              </div>
                              <div>
                                <div>
                                  <p className="line-background-title m-0">
                                    {contentCategory.name
                                      ? contentCategory.name
                                      : ""}
                                  </p>
                                  <span
                                    className={`badge mb-3 p-2 ${
                                      this.state.courseStatus === "COMPLETED"
                                        ? "badge-soft-success"
                                        : "badge-soft-primary"
                                    }`}
                                  >
                                    {this.state.courseStatus}
                                  </span>
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ width: "225px" }}
                                >
                                  <span className="w-100 mr-2">
                                    <ProgressBar
                                      style={{ height: "7px" }}
                                      now={this.state.completedPercentage}
                                    />
                                  </span>
                                  <p className="m-0">
                                    {this.state.completedPercentage !== "NaN"
                                      ? Math.floor(
                                          this.state.completedPercentage,
                                        )
                                      : "0"}
                                    %
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex flex-column flex-md-row align-items-center line-background mb-3">
                              <div className="mr-5">
                                <img
                                  src={
                                    contentCategory?.location
                                      ? defaultUserImage
                                      : defaultUserImage
                                  }
                                  width="150"
                                  height="150"
                                />
                              </div>
                              <div>
                                <div>
                                  <p className="line-background-title m-0">
                                    {contentCategory.name
                                      ? contentCategory.name
                                      : ""}
                                  </p>
                                  <span
                                    className={`badge mb-3 p-2 ${
                                      this.state.courseStatus === "COMPLETED"
                                        ? "badge-soft-success"
                                        : "badge-soft-primary"
                                    }`}
                                  >
                                    {this.state.courseStatus}
                                  </span>
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ width: "225px" }}
                                >
                                  <span className="w-100 mr-2">
                                    <ProgressBar
                                      style={{ height: "7px" }}
                                      now={this.state.completedPercentage}
                                    />
                                  </span>
                                  <p className="m-0">
                                    {this.state.completedPercentage !== "NaN"
                                      ? Math.floor(
                                          this.state.completedPercentage,
                                        )
                                      : "0"}
                                    %
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="content-tabs line-background">
                            <Tabs
                              defaultActiveKey="CONTENT"
                              id="uncontrolled-tab-example"
                              className="mb-3"
                            >
                              <Tab eventKey="CONTENT" title="CONTENT">
                                <span className="font-weight-bold">
                                  {contentCategory.name
                                    ? contentCategory.name
                                    : ""}
                                </span>
                                <hr />
                                {this.state.loading ? (
                                  <Preloader />
                                ) : (
                                  <div
                                    style={{
                                      maxHeight: "426px",
                                      overflowX: "scroll",
                                    }}
                                  >
                                    {contentFiles.length ? (
                                      contentFiles.map((file, index) => {
                                        // console.log('file: ', file);
                                        return (
                                          <div key={index}>
                                            <div
                                              className="d-flex justify-content-between"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                if (
                                                  file?.isLocked !==
                                                    undefined &&
                                                  !file?.isLocked
                                                ) {
                                                  if (
                                                    file?.courseType ===
                                                    courseTypes.FILE
                                                  ) {
                                                    this.getFileFromKey(
                                                      file.key,
                                                      file.title ||
                                                        file.originalname,
                                                      file._id,
                                                      file,
                                                    );
                                                  } else if (
                                                    file?.courseType ===
                                                    courseTypes.QUESTIONER
                                                  ) {
                                                    this.props.history.push(
                                                      `/questions/${file._id}?statusId=${file?.result?._id}&&courseIndex=${this.state?.contentCategory?.index}&&courseCategoryId=${this.state.courseCategoryId}&&courseId=${this.state.contentCategory?._id}`,
                                                    );
                                                  } else if (
                                                    file?.courseType ===
                                                    courseTypes.LINK
                                                  ) {
                                                    startFileWatchEvent();
                                                    stopFileWatchEvent(
                                                      file,
                                                      this.state.currentUser,
                                                    );
                                                    window.open(
                                                      file?.link,
                                                      "_blank",
                                                    );
                                                  }

                                                  if (
                                                    file?.contentType !==
                                                      "video/mp4" &&
                                                    file?.courseType !==
                                                      "QUESTIONER"
                                                  ) {
                                                    this.updateCourseFileStatus(
                                                      file,
                                                      "COMPLETED",
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              <div className="d-flex flex-row">
                                                {this.getIconForFileExtension(
                                                  file?.key,
                                                )}
                                                <span className="p-1">
                                                  {file?.title
                                                    ? file?.title
                                                    : file?.originalname}
                                                </span>
                                              </div>
                                              <div className="d-flex">
                                                {file.isLocked && (
                                                  <div className="category-lock">
                                                    <FcLock size={25} />
                                                  </div>
                                                )}
                                                <div className="ml-2">
                                                  {file?.result?.status ===
                                                  "COMPLETED" ? (
                                                    <span className="text-primary">
                                                      {" "}
                                                      <FaCircleCheck
                                                        color="#47a15f"
                                                        size={25}
                                                      />
                                                      {/* <i className="fas fa-check-circle" /> */}
                                                    </span>
                                                  ) : (
                                                    <FaRegCircleCheck
                                                      color="#47a15f"
                                                      size={25}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <hr></hr>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="d-flex flex-column justify-content-center align-items-center w-100 no-data">
                                        <img
                                          src={require("../images/notfound.png")}
                                          alt=""
                                        />
                                        <div className="text mt-3">
                                          No files have been uploaded.
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Tab>
                            </Tabs>
                          </div>
                        </div>

                        {this.state.splitScreen && (
                          <div className="w-75 h-100 line-background ml-2 d-flex">
                            <div>
                              <FaAngleDoubleLeft
                                size={25}
                                color="white"
                                className="back-course-icon"
                                onClick={() =>
                                  this.setState({ splitScreen: false })
                                }
                              />
                            </div>
                            <div className="ml-3">
                              <h1 className={`font-weight-bold`}>
                                {this.state.filename}
                              </h1>
                              <div className="my-3 d-flex justify-content-center">
                                {this.state.audioModal ? (
                                  // Render the audio modal if audio is present
                                  <div className="player-wrapper">
                                    <ReactPlayer
                                      className="react-player"
                                      url={this.state.mediaUrl}
                                      controls
                                      width="100%"
                                      height="55px"
                                      onStart={() => {
                                        startFileWatchEvent();
                                      }}
                                      onEnded={() => {
                                        this.toggleFeedbackModal();
                                        stopFileWatchEvent(
                                          this.state.selectedFile,
                                          this.state.currentUser,
                                        );
                                        flagFileAsViewed(
                                          this.state.selectedFile,
                                          this.state.currentUser,
                                        );
                                      }}
                                      config={this.getConfig(
                                        this.state.selectedFile?.downloadOption,
                                      )}
                                    />
                                  </div>
                                ) : null}
                                {this.state.videoModal ? (
                                  // Render the video modal if video is present
                                  <div className="player-wrapper h-100">
                                    <ReactPlayer
                                      className="react-player"
                                      url={this.state.mediaUrl}
                                      controls
                                      width="100%"
                                      height="75%"
                                      onStart={() => {
                                        startFileWatchEvent();
                                      }}
                                      onEnded={() => {
                                        this.toggleFeedbackModal();
                                        stopFileWatchEvent(
                                          this.state.selectedFile,
                                          this.state.currentUser,
                                        );
                                        flagFileAsViewed(
                                          this.state.selectedFile,
                                          this.state.currentUser,
                                        );
                                        this.updateCourseFileStatus(
                                          this.state.selectedFile,
                                          "COMPLETED",
                                        );
                                      }}
                                      config={this.getConfig(
                                        this.state.selectedFile?.downloadOption,
                                      )}
                                    />
                                  </div>
                                ) : null}
                                {this.state.imageViewer ? (
                                  <div>
                                    <img
                                      src={this.state.mediaUrl}
                                      className="img-fluid"
                                      alt="resource file"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }} // Adjust the width as needed
                                    />
                                  </div>
                                ) : null}
                                {this.state.fileViewer ? (
                                  <div className="my-5">
                                    <CustomModal
                                      // open={this.state.fileViewer}
                                      // onCloseModal={this.togglefileViewer}
                                      fileType={this.state.fileType}
                                      filePath={this.state.mediaUrl}
                                      fileName={this.state.filename}
                                    />
                                  </div>
                                ) : null}
                                {/* {this.state.pptViewer ? (
                                  <div className="my-5">
                                     <DocumentViewer
                                        url={this.state.mediaUrl}
                                        viewerUrl={this.state.mediaUrl}
                                        viewer={this.state.filename}
                                        // overrideLocalhost="https://react-doc-viewer.firebaseapp.com/" 
                                        />
                                  </div>
                                ) : null} */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        <Modal
                          isOpen={this.state.feedbackModal}
                          centered="true"
                          toggle={this.toggleFeedbackModal}
                        >
                          <ModalHeader toggle={this.toggleFeedbackModal}>
                            Your Feedback
                          </ModalHeader>
                          <ModalBody>
                            <div className="text-center">
                              <p>
                                How likely are you to recommend Employment
                                Ontario Services to someone looking for similar
                                services as those you received?
                              </p>
                            </div>
                            <div>
                              <ReactStars
                                classNames="mx-auto"
                                count={5}
                                size={50}
                                isHalf={false}
                                activeColor="#ffd700"
                                emptyIcon={<i className="far fa-star" />}
                                filledIcon={<i className="fas fa-star" />}
                                onChange={this.handleRatingChanged}
                              />
                            </div>
                            <div>
                              <ol reversed="reversed">
                                <li>Strongly recommend</li>
                                <li>Generally recommend</li>
                                <li>No general opinion</li>
                                <li>Rather not recommend</li>
                                <li>Strongly not recommend</li>
                              </ol>
                            </div>
                            <hr />
                            <div className="form-group required">
                              <label className="control-label">
                                Did you find everything you needed today to
                                assist you with your job search needs?
                              </label>
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  name="foundHelpful"
                                  value="yes"
                                  id="found-helpful-yes"
                                  checked={this.state.hasFoundHelpful === "yes"}
                                  onChange={this.handleFoundHelpfulChanged}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="found-helpful-yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  name="foundHelpful"
                                  value="no"
                                  id="found-helpful-no"
                                  checked={this.state.hasFoundHelpful === "no"}
                                  onChange={this.handleFoundHelpfulChanged}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="found-helpful-no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            <hr />
                            <label>
                              Can you recommend other resources that may be
                              helpful?
                            </label>
                            <textarea
                              className="form-control"
                              name="feedback-comment"
                              onChange={this.handleFeedbackComment}
                              value={this.state.feedbackComment}
                            />
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={this.handleFeedbackFormSubmit}
                            >
                              Submit
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                      {/* <div>
                            <CustomModal
                              open={this.state.fileViewer}
                              onCloseModal={this.togglefileViewer}
                              fileType={this.state.fileType}
                              filePath={this.state.mediaUrl}
                              fileName={this.state.filename}
                            />
                          </div> */}
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
