export const genderMap = {
  male: "M",
  female: "F",
  other: "O",
};

export const IMAGE_TYPES = ["jpg", "jpeg", "png", "svg", "gif", "webp"];

export const GRADIENTS = [
  "linear-gradient(110deg, #53a2ff, #2a387b)",
  "linear-gradient(240deg, #ffaf8a, #ff6263)",
  "linear-gradient(240deg, #e0ec51, #2eb18d)",
  "linear-gradient(242deg, #f49cae, #b05ce2)",
  "linear-gradient(240deg, #8affe7, #5939e0)",
  "linear-gradient(240deg, #fddb92, #09adef)",
  "linear-gradient(240deg, #ffaf8a, #ff6263)",
  "linear-gradient(240deg, #bdd377, #09adef)",
  "linear-gradient(240deg, #0a7dd8, #21c8db)",
  "linear-gradient(240deg, #ff6364, #ff88bc)",
  "linear-gradient(240deg, #55e4ef, #c23af1)",
];

export const AUDIO_FILES = [
  "mp3",
  "wav",
  "ogg",
  "aac",
  "flac",
  "alac",
  "dsd",
  "aiff",
  "m4a",
  "wma",
];
export const VIDEO_FILES = [
  "mp4",
  "wmv",
  "ogv",
  "avi",
  "flv",
  "3gp",
  "mov",
  "webm",
  "3g2",
  "mpg",
  "mkv",
  "m4v",
  "m4a",
  "f4v",
  "f4a",
  "m4b",
  "m4r",
  "f4b",
  "mpeg",
];

export const DOCS_AND_IMAGES = [
  "csv",
  "xlsx",
  "docx",
  "png",
  "jpg",
  "jpeg",
  "webp",
  "svg",
];

export const DOCS_TYPES = [
  "csv",
  "xlsx",
  "docx",
  "pdf",
  "txt"
]

export const PPT_TYPES = [
  "ppt",
  "pptx",
  "odp"
]
