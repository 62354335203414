/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { userService } from "../../_services/admin";
// import mime from "mime-types";
import NocCodeForm from "./NocCodeForm";
import { COMPANY_NAME } from "../../_helpers/companyName";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      user: {},
      loading: true,
      // profileImage: "",
      profilepicture: "assets/images/avatar.png",
      url: "",
      imageLoading: false,
      showImgError: false,
    };
  }

  componentDidMount() {
    userService
      .getUserById(this.state.currentUser?.id)
      .then((data) => {
        if (data.Data.profileImageUrl) {
          this.setState({
            user: data.Data,
            loading: false,
            profilepicture: data.Data.profileImageUrl,
            url: data.Data.profileImageUrl,
          });
        } else {
          this.setState({ user: data.Data, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log("error", error);
      });
  }

  profileImageEdit = () => {
    this.setState({
      profilepicture: "",
      url: "",
    });
  };

  onChangeFileHandler = (event) => {
    this.setState({
      profilepicture: "",
      url: "",
      imageLoading: true,
      showImgError: false,
    });
    if (event.target.files[0]) {
      const file = event.target.files[0];
      // Split the filename to get the name and type
      const fileName = `user-profile/${event.target.files[0].name}`;
      const fileType = event.target.files[0].type;
      // let fileType = mime.lookup(event.target.files[0].name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/users/profile-image`,
          {
            fileName,
            fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser?.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"), 10)
                : 0,
            },
          },
        )
        .then((response) => {
          const returnData = response?.data?.Data;
          const { signedRequest } = returnData;
          const { url } = returnData;
          this.setState({ url });
          // Put the fileType in the headers for the upload
          const options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then(() => {
              this.setState({
                imageLoading: false,
                profilepicture: url,
              });
            })
            .catch((error) => {
              alert(`ERROR ${JSON.stringify(error)}`);
            });
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    } else {
      // document.getElementById("img_url").src = "";
      // this.setState({
      //   url: "",
      //   imageLoading: false,
      //   showImgError: true,
      // });
      // document.getElementById("validimage").innerHTML = "Choose Your Image"
    }
  };

  showWarningAlertOnDeleteAccount = () => {
    swal(
      "Are you sure you want to remove this account? Once removed you wont be able to login back!",
      {
        dangerMode: true,
        buttons: true,
      },
    )
      .then((isConfirm) => {
        if (isConfirm) {
          const payload = {
            userId: this.state.currentUser?.id,
          };
          userService
            .updateUserActiveStatus(payload)
            .then(() => {
              authenticationService.logout();

              swal("Account Deleted Successfully", "", "success");
            })
            .catch((error) => console.log("Error", error));

          // history.push("/");
        }
      })
      .catch((error) => console.log("Error", error));
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">My Profile</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">My Profile</li>
                </ol>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        firstName: this.state?.user?.firstName || "",
                        lastName: this.state?.user?.lastName || "",
                        email: this.state?.user?.email || "",
                        userId: this.state?.currentUser?.id || "",
                        currentUserId: this.state?.currentUser?.id || "",
                        organization: this.state?.user?.organization || "",
                        department: this.state?.user?.department || "",
                        role: this.state?.user?.role || 3,
                        jobTitle: this.state?.user?.jobTitle || "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required("Email is required"),
                        firstName: Yup.string().required(
                          "First Name is required",
                        ),
                        lastName: Yup.string().required(
                          "Last Name is required",
                        ),
                        department: Yup.string().required(
                          "Department is required",
                        ),
                        organization: Yup.string().required(
                          "Organization is required",
                        ),
                        jobTitle: Yup.string().required(
                          "Job Title is required",
                        ),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        setStatus();
                        // formData.profileImageUrl = this.state.url;
                        // deep copy formdata to userdata to prevent change in ref variables
                        const userData = JSON.parse(JSON.stringify(formData));
                        userService
                          .updateProfile(userData)
                          .then(
                            () => {
                              // const { from } = this.props.location.state || { from: { pathname: "/login" } };
                              // this.props.history.push(from);
                              setSubmitting(false);
                              NotificationManager.success(
                                "Profile Updated Successfully",
                              );
                              authenticationService.refreshUserProfile(
                                userData,
                              );
                            },
                            (error) => {
                              setSubmitting(false);
                              setStatus(error);
                            },
                          )
                          .catch((err) => {
                            console.log("error ----> ", err);
                            setSubmitting(false);
                            setStatus(err);
                          });
                      }}
                      render={({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        values,
                        handleChange,
                      }) => (
                        <Form>
                          <div className="form-material">
                            <div className="form-group required">
                              <label className="control-label">
                                Legal First Name
                              </label>
                              <Field
                                name="firstName"
                                type="text"
                                placeholder="Name"
                                className={`form-control${
                                  errors.firstName && touched.firstName
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">
                                {" "}
                                Legal Last Name
                              </label>
                              <Field
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                                className={`form-control${
                                  errors.lastName && touched.lastName
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">
                                Select Organization
                              </label>
                              <Field
                                name="organization"
                                as="select"
                                className={`form-control${
                                  errors.organization && touched.organization
                                    ? " is-invalid"
                                    : ""
                                }`}
                                placeholder="Select Organization"
                              >
                                <option value="">Select Organization</option>
                                {COMPANY_NAME.map((val) => (
                                  <option value={val.value}>{val.name}</option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="organization"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">
                                Department Name
                              </label>
                              <Field
                                name="department"
                                type="text"
                                placeholder="Department Name"
                                className={`form-control${
                                  errors.department && touched.department
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="department"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">Job Title</label>
                              <Field
                                name="jobTitle"
                                type="text"
                                placeholder="Department Name"
                                className={`form-control${
                                  errors.jobTitle && touched.jobTitle
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="jobTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Email</label>
                              <Field
                                name="email"
                                disabled
                                type="text"
                                placeholder="Email"
                                validate={validateEmail}
                                className={`form-control${
                                  errors.email && touched.email
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            {/* <div className="form-group required">
                              <div>
                                <label className="control-label">
                                  {" "}
                                  Profile Picture
                                </label>
                              </div>
                              <div
                                className="d-flex browseBTN"
                                style={{ marginTop: "5px" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ flexDirection: "column" }}
                                >
                                  <input
                                    name="url"
                                    className="inputFille"
                                    type="file"
                                    onChange={this.onChangeFileHandler}
                                    onClick={this.profileImageEdit}
                                  />
                                </div>

                                {this.state.imageLoading && (
                                  <img
                                    alt=""
                                    style={{ height: "27px" }}
                                    src="assets/images/Spinner.svg"
                                  />
                                )}
                                {this.state.profilepicture ? (
                                  <img
                                    src={this.state.profilepicture}
                                    height="80px"
                                    width="80px"
                                    id="img_url"
                                    alt="Profile Picture"
                                  />
                                ) : (
                                  <img
                                    src="assets/images/avatar.png"
                                    id="img_url"
                                    alt="Profile Picture"
                                  />
                                )}
                              </div>
                            </div> */}

                            <div className="form-group text-center mb-0 m-t-20">
                              <div className="col-xs-12 text-right">
                                <Link
                                  to="/"
                                  className="btn btn-outline-secondary mr-2"
                                >
                                  Cancel
                                </Link>
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn btn-purple text-uppercase waves-effect waves-light"
                                >
                                  Update
                                </button>
                                {isSubmitting && (
                                  <img alt="" src="assets/images/Spinner.svg" />
                                )}
                              </div>
                            </div>
                            {status && (
                              <div className="alert alert-danger mt-2">
                                {status}
                              </div>
                            )}
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
