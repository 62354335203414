import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import { NotificationManager } from "react-notifications";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { setUserData } from "../../redux-store/action";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class NocCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      nocDetail: [],
    };
  }

  componentDidMount() {
    userService
      .getUserById(this.state.currentUser?.id)
      .then((data) => {
        this.setState({
          nocDetail: data.Data.nocDetail.map((elem) => {
            return elem.description;
          }),
        });
      })
      .catch((error) => console.log("Error", error));

    const scripts = ["/assets/js/custom.min.js"];
    const host = window.location.origin;
    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = host + t;
      script.async = false;

      document.body.appendChild(script);
      return true;
    });
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          userId: this.state.currentUser?.id,
          nocDetail: this.state.nocDetail || [],
        }}
        onSubmit={(formData, { setStatus, setSubmitting }) => {
          setStatus();
          userService
            .updateNocDetail(formData)
            .then(
              () => {
                setSubmitting(false);
                NotificationManager.success(
                  "Noc Code Detail Updated Successfully",
                );
                // authenticationService.refreshUserProfile(formData);
              },
              (error) => {
                setSubmitting(false);
                setStatus(error);
              },
            )
            .catch((err) => {
              console.log("error ----> ", err);
              setSubmitting(false);
              setStatus(err);
            });
        }}
        render={({ status, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Management occupations"
                  id="managementOccupation"
                  className="form-check-input"
                />
                <label
                  className="form-check-label"
                  htmlFor="managementOccupation"
                >
                  Management occupations
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Business, finance & administration"
                  id="business"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="business">
                  Business, finance & administration
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Natural and applied sciences"
                  id="sciences"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="sciences">
                  Natural and applied sciences
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Health occupations"
                  id="occupations"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="occupations">
                  Health occupations{" "}
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Social science, education, government service"
                  id="services"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="services">
                  Social science, education, government service
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Art, culture, recreation and sport"
                  id="sports"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="sports">
                  Art, culture, recreation and sport
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Sales and service"
                  id="sales"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="sales">
                  Sales and service
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Trades, transport and equipment operators"
                  id="operators"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="operators">
                  Trades, transport and equipment operators
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Occupations unique to primary industry"
                  id="occupations_primary"
                  className="form-check-input"
                />
                <label
                  className="form-check-label"
                  htmlFor="occupations_primary"
                >
                  Occupations unique to primary industry
                </label>
              </div>
              <div className="form-check">
                <Field
                  name="nocDetail"
                  type="checkbox"
                  value="Occupations unique to processing, manufacturing"
                  id="occupations_processing"
                  className="form-check-input"
                />
                <label
                  className="form-check-label"
                  htmlFor="occupations_processing"
                >
                  Occupations unique to processing, manufacturing
                </label>
              </div>
            </div>
            <div className="form-group text-center mb-0 m-t-20">
              <div className="col-xs-12 text-right">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-purple text-uppercase waves-effect waves-light"
                >
                  Update
                </button>
                {isSubmitting && (
                  <img
                    alt=""
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />
                )}
              </div>
            </div>
            {status && <div className="alert alert-danger mt-2">{status}</div>}
          </Form>
        )}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NocCodeForm);
