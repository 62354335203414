/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import "./App.css";
import jQuery from "jquery";
import IdleTimer from "react-idle-timer";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import RegistrationPage from "./pages/RegistrationPage";
import ContentLibrary from "./pages/user/ContentLibrary";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import CategoryDetail from "./pages/user/CategoryDetail";
import ClientDashboard from "./pages/user/ClientDashboard";
import { apiUrl } from "./config";
import changePassword from "./pages/user/changePassword";
import AccountVerification from "./pages/AccountVerification";
import MyProfile from "./pages/user/MyProfile";
import Websdk from "./_helpers/analytics";
import { history, Role, genderMap } from "./_helpers";
import { authenticationService } from "./_services";
import PrivateRoute from "./components/PrivateRoute";
import { getBirthYear } from "./_helpers/_helperFunctions";
import IdleTimeOutModal from "./components/IdleTimeOutModal";
import { updateAppointment, updateWorkshop } from "./redux-store/action";
import Questioner from "./pages/user/Questioner";
import CourseCategory from "./pages/user/CourseCategory";

window.jQuery = jQuery;

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => {
      dispatch(updateAppointment(data));
    },

    updateWorkshop: (data) => {
      dispatch(updateWorkshop(data));
    },
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      timeout: 1000 * 60 * 20,
      showModal: false,
      isTimedOut: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    if (!localStorage.getItem("isenc")) {
      localStorage.setItem("isenc", "0");
    }
    function keyDownTextField(e) {
      if (e.ctrlKey && e.altKey && e.key === "e") {
        const encMode = localStorage.getItem("isenc")
          ? localStorage.getItem("isenc")
          : "0";
        if (encMode !== "1") {
          localStorage.setItem("isenc", "1");
          alert("Enc Mode On");
        } else {
          localStorage.setItem("isenc", "0");
          alert("Enc Mode Off");
        }
      }
    }
    document.addEventListener("keydown", keyDownTextField, false);

    if (!localStorage.getItem("pemPublic")) {
      fetch("./security/public_client.pem", { mode: "no-cors" })
        .then((response) => response.text())
        .then((data) => {
          fetch("./security/private_client.pem", { mode: "no-cors" })
            .then((response1) => response1.text())
            .then((data1) => {
              localStorage.setItem("pemPrivate", data1);
              localStorage.setItem("pemPublic", data);
            });
        })
        .catch((error) => console.error(error));
    }
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((x) => {
      this.setState(
        {
          currentUser: x,
        },
        () => {
          const { currentUser } = this.state;
          if (currentUser && window.Websdk) {
            window.Websdk.q.push([
              "user_details",
              {
                name: `${currentUser.firstName} ${currentUser.lastName}`,
                username:
                  `${currentUser.firstName}.${currentUser.lastName}`.toLowerCase(),
                email: currentUser.email,
                phone: currentUser.phoneNumber,
                gender: genderMap[currentUser.gender],
                byear: getBirthYear(currentUser.dob),
                custom: {
                  id: currentUser.id,
                },
              },
            ]);
          }
        },
      );
    });

    const host = window.location.origin;

    const cssFiles = [];
    cssFiles.map((t) => {
      const link = document.createElement("link");
      link.href = host + t;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      return true;
    });
    const scripts = [
      `${host}/assets/plugins/jquery/jquery.min.js`,
      // 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js',
      "https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/5.0.4-beta.33/bindings/inputmask.binding.min.js",
      `${host}/assets/plugins/popper/popper.min.js`,
      `${host}/assets/plugins/bootstrap/js/bootstrap.min.js`,
      `${host}/assets/js/jquery.slimscroll.js`,
      `${host}/assets/js/waves.js`,
      `${host}/assets/js/sidebarmenu.js`,
      `${host}/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js`,
      `${host}/assets/plugins/sparkline/jquery.sparkline.min.js`,
      `${host}/assets/plugins/styleswitcher/jQuery.style.switcher.js`,
      `${host}/assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js`,
      `${host}/assets/plugins/select2/dist/js/select2.full.min.js`,
      // host + "/assets/js/custom.min.js",
    ];

    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });

    if (
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "127.0.0.1"
    ) {
      // // end Session on browser or tab close.
      // window.addEventListener("beforeunload", function (e) {
      //   authenticationService.logout();
      // });

      // Exposing Analytics Websdk to the DOM as a global variable

      window.Websdk = Websdk;
      Websdk.init({
        app_key: "539f67bf-0590-4c0c-bf6d-25c309d94caf",
        url: `${process.env.REACT_APP_API_URL}/api/v1/analytics`,
        session_update: 60,
        use_session_cookie: false,
        debug: process.env.NODE_ENV === "development",
        require_consent: false,
        namespace: "LMS-WestOfWindsor",
        inactivity_time: 1,
        offline_mode: false,
        force_post: true,
      });

      // Since Countly is loaded and available, you can use synchronus or asynchronus calls, does not matter
      Websdk.q.push([
        "group_features",
        {
          activity: ["sessions", "events", "views", "location"],
          interaction: ["scrolls", "clicks"],
          whereabouts: ["users"],
        },
      ]);

      Websdk.q.push(["track_sessions"]);
      Websdk.q.push(["track_pageview", "home"]);
    }
  }

  onAction = () => {
    this.setState({ isTimedOut: false });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleLogout = () => {
    this.setState({ showModal: false });
    authenticationService.logout();
    history.push("/");
  };

  onIdle = () => {
    const { isTimedOut } = this.state;
    if (isTimedOut) {
      history.push("/login");
    } else if (history.location.pathname !== "/") {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  };

  render() {
    const { timeout } = this.state;
    return (
      <div className="App">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={timeout}
        />
        <Router history={history}>
          {/* Common Routes */}
          <Route exact path="/" component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/register" component={RegistrationPage} />
          <Route path="/reset/:token" component={ResetPasswordPage} />
          <Route path="/verification/:token" component={AccountVerification} />

          {/* User's Routes */}
          <PrivateRoute
            exact
            path="/dashboard"
            roles={{ role: Role.User }}
            component={ClientDashboard}
          />
          <PrivateRoute
            exact
            path="/course-categories"
            roles={{ role: Role.User }}
            component={CourseCategory}
          />
          <PrivateRoute
            exact
            path="/course-category/:courseCategoryId"
            roles={{ role: Role.User }}
            component={ContentLibrary}
          />
          <PrivateRoute
            path="/questions/:id"
            roles={{ role: Role.User }}
            component={Questioner}
          />
          <PrivateRoute
            path="/course-category/:courseCategoryId/category-detail/:id"
            roles={{ role: Role.User }}
            component={CategoryDetail}
          />
          <PrivateRoute
            path="/change-password"
            roles={{ role: Role.User }}
            component={changePassword}
          />
          <PrivateRoute
            path="/my-profile"
            roles={{ role: Role.User }}
            component={MyProfile}
          />
        </Router>
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
