/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Badge from "react-bootstrap/Badge";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { GRADIENTS } from "../../_helpers";
import { eventService } from "../../_services/staff";
import RegistrationConfirmation from "../../shared/RegistrationConfirmation";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import NocCodeForm from "./NocCodeForm";

function mapStateToProps(state) {
  return {
    workshopChangeId: state.workshopChangeId,
    updatedAppointment: state.updatedAppointment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class ClientDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      modal: false,
      appointmentDetails: {},
      eventList: [],
      workshopList: [],
      totalBooking: null,
      noAppointment: true,
      contactModal: false,
      nocModal: false,
    };
  }

  componentDidMount() {
    userService
      .setNewUserFalse({ userId: this.state.currentUser?.id })
      .then(() => {})
      .catch((error) => console.log("Error", error));
    this.getAllCurrentWorkshops();
    this.getAllCurrentEvents();
    const scripts = [
      "/assets/plugins/echarts/echarts-all.js",
      "/assets/plugins/echarts/echarts-init.js",
      "/assets/js/custom.min.js",
    ];
    const host = window.location.origin;
    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = host + t;
      script.async = false;

      document.body.appendChild(script);
      return true;
    });

    if (this.state.currentUser?.newUser) {
      this.setState({
        nocModal: true,
      });
      // this.setState({isPreferrenceModal: true})
    }
  }

  componentDidUpdate(prevProps) {
    const prevWorkshopId = prevProps.workshopChangeId;
    const newWorkshopId = this.props.workshopChangeId;

    if (prevWorkshopId !== newWorkshopId) {
      if (this.state.selectedTab === "all") {
        this.getAllCurrentWorkshops();
        this.getAllCurrentEvents();
      }
    }
  }

  getAllCurrentWorkshops() {
    eventService
      .getAllCurrentEvents(new Date().getTime(), this.state.currentUser?.id)
      .then((data) => {
        if (data.Status && data.Data.length) {
          this.setState({
            workshopList: data.Data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllCurrentEvents() {
    eventService
      .getAllCurrentEvents(
        new Date().getTime(),
        this.state.currentUser?.id,
        "event",
      )
      .then((data) => {
        if (data.Status && data.Data.length) {
          this.setState({
            eventList: data.Data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleContact = () => {
    this.setState((state) => {
      return { contactModal: !state.contactModal };
    });
  };

  toggleNocModal = () => {
    this.setState((state) => {
      return { nocModal: !state.nocModal };
    });
  };

  generate = (id, gradientColor) => {
    if (gradientColor) {
      return gradientColor;
    }
    if (id >= GRADIENTS.length - 1) {
      return GRADIENTS[Math.floor(Math.random() * GRADIENTS.length)];
    }
    return GRADIENTS[id];
  };

  changeIconColor = (id, eventId) => {
    if (document.getElementById(id).style[0] === "color") {
      // document.getElementById(id).style.removeProperty('color');
      document.getElementById(id).disabled = true;
    } else {
      document.getElementById(id).style.color = "blue";
    }
    const whishlistData = {
      userId: this.state.currentUser?.id,
      userName: `${this.state.currentUser?.firstName} ${this.state.currentUser?.lastName}`,
      userEmail: this.state.currentUser?.email,
      eventId,
      currentUserId: this.state.currentUser?.id,
    };
    eventService
      .addToWishlist(whishlistData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Added to Wishlist", "", 5000);
          this.getAllCurrentWorkshops();
        }
        // else {
        //   console.log("register event data on err: ", data)
        // }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  };

  showIsRegistered = (item, index) => {
    const end = moment(new Date());
    const endTime = moment(item.endTime);
    const startTime = moment(new Date(item.startTime));
    const isJoinable = end.isBetween(
      startTime.subtract(30, "minutes"),
      endTime,
    );
    return isJoinable ? (
      <a
        href={item.accessLink}
        target="_blank"
        className="btn register_btn mr-3 w-100"
      >
        Join Now
      </a>
    ) : (
      <a
        id={index}
        style={{ color: "white" }}
        className="btn btn-dark mr-3 register_btn w-100 disabled"
      >
        Registered
      </a>
    );
  };

  registerForEvent(eventId) {
    const registrationData = {
      userId: this.state.currentUser?.id,
      userName: `${this.state.currentUser?.firstName} ${this.state.currentUser?.lastName}`,
      userEmail: this.state.currentUser?.email,
      eventId,
      currentUserId: this.state.currentUser?.id,
    };
    // document.getElementById(index).innerHTML= "Registered"
    eventService
      .registerEvent(registrationData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Successfully Registered", "", 5000);
          this.getAllCurrentWorkshops();
        } else {
          console.log("register event data on err: ", data);
        }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  }

  renderBanner = (index, item) => {
    if (item.type === "workshop") {
      return (
        <div
          id={`grad-${index}`}
          className="d-flex py-3 border-bottom setheight"
          style={{
            background: this.generate(index, item.gradientColor),
          }}
        >
          <div className="author col-xs-2 col-sm-3 align-self-center">
            <div className="profile-image d-flex">
              <img
                async
                src={
                  item?.facilitatorImage ||
                  "/assets/images/user_placeholder.jpg"
                }
              />
            </div>
            <div className="facilitorname">{item?.facilitatorName}</div>
          </div>
          <div className="event_name align-self-center">
            {item?.name?.toUpperCase()}
          </div>
        </div>
      );
    }
    if (item.bannerImage) {
      return (
        <div
          style={{
            backgroundImage: `url(${item.bannerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex py-3 border-bottom setheight justify-content-center"
        >
          <div className="event_name align-self-center transbox">
            {item.name.toUpperCase()}
          </div>
        </div>
      );
    }
    return (
      <div
        id={`grad-${index}`}
        className="d-flex py-3 border-bottom setheight justify-content-center"
        style={{
          background: this.generate(index, item.gradientColor),
        }}
      >
        <div className="event_name align-self-center">
          {item.name.toUpperCase()}
        </div>
      </div>
    );
  };

  renderRegisterButton = (item, index) => {
    if (item) {
      const startTime = moment(new Date(item.startTime));
      const currentTime = moment();
      if (currentTime.isBefore(startTime)) {
        return (
          <a
            id={index}
            className="btn register_btn mr-3 w-100"
            onClick={() => document.getElementById(item._id).click()}
          >
            Register
          </a>
        );
      }
      return (
        <button id={index} className="btn register_btn mr-3 w-100" disabled>
          Closed
        </button>
      );
    }
    return null;
  };

  renderEventFooter = (index, item) => {
    if (item.type === "workshop") {
      return (
        <div className="card-footer">
          <div className="d-flex">
            {item.isRegistered ? (
              this.showIsRegistered(item, index)
            ) : (
              <>
                {this.renderRegisterButton(item, index)}
                <RegistrationConfirmation
                  id={item._id}
                  confirm={() =>
                    this.registerForEvent(item._id, index, item.accessLink)
                  }
                />
              </>
            )}
            {item.isRegistered ? (
              <i
                id={`icon-${item._id}`}
                className="mdi mdi-bookmark mdi-24px d-flex bookmark"
              />
            ) : (
              <>
                {item.wishlisted ? (
                  <i
                    id={`icon-${item._id}`}
                    style={{ color: "blue" }}
                    className="mdi mdi-bookmark mdi-24px d-flex bookmark"
                  />
                ) : (
                  <i
                    id={`icon-${item._id}`}
                    className="mdi mdi-bookmark mdi-24px d-flex bookmark cursor_pointer"
                    onClick={() =>
                      this.changeIconColor(`icon-${item._id}`, item._id)
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="card-footer">
        <a
          href={item.accessLink}
          className="btn register_btn mr-3 w-100"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register
        </a>
      </div>
    );
  };

  render() {
    const { eventList, workshopList } = this.state;
    const commonList = workshopList.concat(eventList);
    workshopList.sort((a, b) => {
      return a.dateTime - b.dateTime;
    });
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* <Preloader /> */}
        {/* <HeaderAdmin />
        <Sidebaar /> */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>

            <div className="row mt-3 py-4">
              <div className="col-md-12">
                <div className="welcome-card">
                  <div className="card">
                    <h2
                      className="mt-2"
                      style={{
                        textAlign: "center",
                        // fontSize: "3rem",
                        padding: "1rem",
                      }}
                    >
                      Welcome to Women's Enterprise Skills Training...
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
