export const COMPANY_NAME = [
    { name: "Adult Language and Learning", value: "ALAL" },
    {
      name: "Centre Communautaire Francophone Windsor Essex Kent Inc. (CCFWEK)",
      value: "CCFWEK",
    },
    { name: "Collège Boréal", value: "CB" },
    { name: "South Essex Community Council (SECC)", value: "SECC" },
    {
      name: "The Multicultural Council of Windsor and Essex County (MCC)",
      value: "MCC",
    },
    { name: "The Windsor Women Working With Immigrant Women (W5)", value: "W5" },
    { name: "Unemployed Help Centre of Windsor Inc. (UHC)", value: "UHC" },
    { name: "YMCA of Southwestern Ontario", value: "YMCA" },
    {
      name: "Windsor Essex Local Immigration Partnership (WE LIP)",
      value: "WELIP",
    },
    {
      name: "Women’s Enterprise Skills Training of Windsor Inc. (WEST)",
      value: "WEST",
    },
    { name: "New Canadians’ Centre of Excellence Inc.", value: "NCCOEI" },
    {
      name: "Réseau en Immigration Francophone du Centre Sud-Ouest (RIFCSO)",
      value: "RIFCSO",
    },
    {
      name: "Greater Essex County District School Board Language  Assessment & Resource Centre (GECDSB LARC)",
      value: "GECDSBLARC",
    },
  ];
  