import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";

function updateFileStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/file-user-status/update`,
    requestOptions,
  ).then(handleResponse);
}

export const fileUserStatusService = {
  updateFileStatus,
};
