const LIMIT = 12;
const PAGES_COUNT = 5;

const courseTypes = {
  QUESTIONER: "QUESTIONER",
  FILE: "FILE",
  LINK: "LINK",
};

const notificationStatus = {
  UNREAD: "UNREAD",
  READ: "READ",
  ARCHIVE: "ARCHIVE",
};

module.exports = { LIMIT, PAGES_COUNT, courseTypes, notificationStatus };
