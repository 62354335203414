/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import contentLibraryService from "../../_services/staff/contentLibrary.service";
import { QuestionType } from "../../_helpers/questionType";
import { fileUserStatusService } from "../../_services/staff/fileUserStatus.service";
import swal from "sweetalert";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function startFileWatchEvent() {
  if (window.Websdk) {
    window.Websdk.q.push(["start_event", "fileWatch"]);
  }
}

function stopFileWatchEvent(file, user) {
  if (window.Websdk) {
    window.Websdk.q.push([
      "end_event",
      {
        key: "fileWatch",
        segmentation: {
          fileId: file._id,
          fileName: file.originalname,
          fileTitle: file.title,
          userName: `${user?.firstName} ${user?.lastName}`,
          userId: user.id,
          userEmail: user.email,
        },
      },
    ]);
  }
}

class Questioner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      loading: true,
      fileId: "",
      fileDetails: {},
      answerArray: [],
      fileStatusId: "",
      courseIndex: "",
      fileStatusDetails: {},
      courseCategoryId: "",
      courseId: "",
    };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    startFileWatchEvent();
    const queryParams = new URLSearchParams(window.location.search);
    const statusId = queryParams.get("statusId");
    const index = queryParams.get("courseIndex");
    const courseCategoryId = queryParams.get("courseCategoryId");
    const courseId = queryParams.get("courseId");
    this.setState({
      fileStatusId: statusId,
      courseIndex: Number(index),
      courseCategoryId,
      courseId,
    });
    this.getQuestions();
    this.getFileStatus(statusId);
  }

  componentWillUnmount() {
    stopFileWatchEvent(this.state.fileDetails, this.state.currentUser);
  }

  getQuestions() {
    this.setState({
      loading: true,
    });
    const id = this.props?.match?.params?.id;
    if (id) {
      this.setState({
        fileId: id,
      });
      contentLibraryService
        .getFileDetailsById(id)
        .then((data) => {
          this.setState({
            fileDetails: data?.Data,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({
            loading: false,
          });
        });
    }
  }

  getFileStatus(id) {
    if (id) {
      contentLibraryService
        .getFileStatusById(id)
        .then((data) => {
          this.setState({
            fileStatusDetails: data?.Data,
          });
        })
        .catch((error) => {});
    }
  }

  handleChange(e, id, type) {
    const arr = this.state.answerArray;
    const index = arr?.findIndex((val) => val.questionsId === id);
    if (index === -1) {
      arr.push({
        questionsId: id,
        optionType: type,
        answerKeys: [e.target.value],
      });
    } else {
      if (type === QuestionType.MULTIPLE_CHOICE) {
        arr[index] = {
          ...arr[index],
          answerKeys: [e.target.value],
        };
      } else {
        const keyArr = arr[index]?.answerKeys;
        if (e.target.checked) {
          keyArr.push(e.target.value);
        } else {
          const keyIndex = keyArr.indexOf(e.target.value);
          if (keyIndex !== -1) {
            keyArr.splice(keyIndex, 1);
          }
        }
        arr[index] = {
          ...arr[index],
          answerKeys: keyArr,
        };
      }
    }
  }

  goBack() {
    swal.close();
    this.props.history.go(-1);
  }

  submitQuestions() {
    if (this.state?.answerArray?.length) {
      const payload = {
        userId: this.state?.currentUser?.id,
        fileId: this.state?.fileDetails?._id,
        answerArray: this.state.answerArray,
        totalQuestions: this.state?.fileDetails?.questions?.length,
      };
      contentLibraryService
        .getAnswerCount(payload)
        .then((data) => {
          const response = data?.Data?.Data;
          if (data?.Data?.Data) {
            swal({
              title: `Answer Submitted `,
              text: `Correct Answer: ${
                response?.trueCount || 0
              }, Wrong Answer: ${response?.falseCount || 0}`,
              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                  <div style="margin-bottom: 0.5rem">
                    <h5 style="color: green; font-weight: 500">True Answers</h5>
                    <ol style="display: flex; flex-direction: column; margin: 0rem 6rem">
                      ${response?.rightAnswersArray
                        .map(
                          (item) => `
                      <li style="text-align: left">- ${item.trueAnswers.join(
                        ", ",
                      )}</li>
                      `,
                        )
                        .join("")}
                    </ol>
                  </div>
                  
                  <button class="btn btn-success pl-3 pr-3" id="button1">Ok</button>
                  <button class="btn btn-danger ml-2" id="button2">Attempt Again</button>                  
                  `,
                },
              },
              buttons: false,
              icon: "success",
            }).then((res) => {
              if (res === "button1") {
                this.props.history.go(-1);
              } else {
                swal.close();
              }
            });

            document
              .getElementById("button1")
              .addEventListener("click", this.goBack);

            document
              .getElementById("button2")
              .addEventListener("click", function () {
                swal.close();
              });
          }
          if (
            this.state?.fileStatusDetails?.status === "TODO" &&
            this.state?.fileDetails?.questions?.length === response?.trueCount
          ) {
            const statusPayload = {
              id: this.state?.fileStatusId,
              updateData: {
                status: "COMPLETED",
              },
              index: this.state?.fileDetails?.index,
              courseId: this.state?.fileDetails?.category,
              userId: this.state?.currentUser?.id,
              courseIndex: this.state?.courseIndex,
              courseCategoryId: this.state.courseCategoryId,
            };
            fileUserStatusService
              .updateFileStatus(statusPayload)
              .then((data) => {})
              .catch((error) => console.log("Error", error));
          }
          this.setState({
            answerArray: [],
          });
          this.getQuestions();
          this.getFileStatus(this.state?.fileStatusId);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading ? <Preloader /> : ""}
        {/* <HeaderAdmin /> */}
        {/* <Sidebaar /> */}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Quiz</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/course-categories`)
                    }
                  >
                    Categories
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(
                        `/course-category/${this.state.courseCategoryId}`,
                      )
                    }
                  >
                    Courses
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(
                        `/course-category/${this.state.courseCategoryId}/category-detail/${this.state.courseId}`,
                      )
                    }
                  >
                    Course Detail
                  </li>
                  <li className="breadcrumb-item active">Quiz</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div
                          className="card-body p-2 d-flex"
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h3 className="mb-0 ml-1">
                              {this.state?.fileDetails?.title}
                            </h3>
                            <h4 className="mb-0 ml-1">
                              {this.state?.fileDetails?.description}
                            </h4>
                          </div>
                          <div>
                            <p className="mb-0 mr-2">
                              Total Attempt:{" "}
                              {this.state?.fileStatusDetails?.attemptCount
                                ?.length || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                      <form>
                        {this.state.fileDetails?.questions?.map(
                          (item, index) => (
                            <>
                              <div className="card">
                                <div className="card-body">
                                  <div className="form-group mb-1">
                                    <div className="d-flex">
                                      <h4>{index + 1}.</h4>
                                      <p className="ml-2">{item?.question}</p>
                                    </div>
                                    {item?.options?.map((val, subIndex) => (
                                      <div className="checkbox checkbox-primary p-t-0">
                                        <input
                                          type={
                                            item?.optionType ===
                                            QuestionType.CHECKBOX
                                              ? "checkbox"
                                              : "radio"
                                          }
                                          name={`question${index}`}
                                          id={`question${index}_${subIndex}`}
                                          onClick={(e) =>
                                            this.handleChange(
                                              e,
                                              item?._id,
                                              item?.optionType,
                                            )
                                          }
                                          value={val.optionKey}
                                        />
                                        <label
                                          name={`question${index}`}
                                          htmlFor={`question${index}_${subIndex}`}
                                        >
                                          {val.option}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </>
                          ),
                        )}
                        <button
                          type="reset"
                          className="btn btn-primary text-uppercase waves-effect waves-light"
                          onClick={() => this.submitQuestions()}
                          disabled={!this.state?.answerArray?.length}
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Questioner);
