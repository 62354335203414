import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PasswordMask from "react-password-mask";
import MaskedInput from "react-text-mask";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";
import { authenticationService } from "../_services";
import { userService } from "../_services/admin";
import Preloader from "../components/Preloader";
import { Role } from "../_helpers";
import { COMPANY_NAME } from "../_helpers/companyName";

function mapStateToProps(state) {
  return {
    count: state.count,
  };
}

function mapDispatchToProps() {
  return {};
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

class RegistrationPage extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    const scripts = ["/assets/js/custom.min.js"];
    const host = window.location.origin;
    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = host + t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });
  }

  toggleModal = () => {
    if (this.state.modal) {
      const { from } = this.props.location.state || {
        from: { pathname: "/" },
      };
      this.props.history.push(from);
    }

    this.setState((state) => {
      return { modal: !state.modal };
    });
  };

  render() {
    return (
      <>
        {/* <Preloader /> */}
        <section id="wrapper">
          <div className="signup-page pt-4 pb-4">
            <div className="login-box card">
              <div className="card-body">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    role: 1,
                    organization: "",
                    jobTitle: "",
                    department: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required("Email is required"),
                    password: Yup.string().when({
                      is: (val) => val?.length > 0,
                      then: Yup.string().when({
                        is: (inVal) => inVal?.length > 0,
                        then: Yup.string()
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])/,
                            "Must have both uppercase and lowercase letters",
                          )
                          .matches(/^(?=.*[0-9])/, "Must consist of a number")
                          .matches(
                            /^(?=.{8,})/,
                            "Must be atleast 8 character long",
                          )
                          .required("Password is required"),
                      }),
                      otherwise: Yup.string()
                        .matches(
                          /^(?=.*[a-z])(?=.*[A-Z])/,
                          "Must have both uppercase and lowercase letters",
                        )
                        .matches(/^(?=.*[0-9])/, "Must consist of a number")
                        .matches(
                          /^(?=.{8,})/,
                          "Must be atleast 8 character long",
                        )
                        .required("Password is required"),
                    }),
                    confirmPassword: Yup.string().when("password", {
                      is: (val) => val?.length > 0,
                      then: Yup.string()
                        .oneOf(
                          [Yup.ref("password")],
                          "Must be same as Password",
                        )
                        .required("Confirm Password is required"),
                    }),
                    firstName: Yup.string().required("First Name is required"),
                    lastName: Yup.string().required("Last Name is required"),
                    // dob: Yup.string().required("Date of Birth is required"),
                    organization: Yup.string().required(
                      "Organization is required",
                    ),
                    jobTitle: Yup.string().required("Job Title is required"),
                    department: Yup.string().required("Deaprtment is required"),
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    setStatus();
                    delete formData.confirmPassword;
                    const userData = {
                      ...formData,
                      // dob: moment(formData.dob, "MM/DD/YYYY").toDate(),
                      role: Role.User,
                    };

                    userService.register(userData).then(
                      () => {
                        setSubmitting(false);
                        this.setState({ modal: true });
                      },
                      (error) => {
                        setSubmitting(false);
                        setStatus(error);
                      },
                    );
                  }}
                  render={({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                    handleChange,
                  }) => (
                    <Form>
                      <div
                        className="form-horizontal form-material"
                        id="loginform"
                      >
                        <img
                          className="loginlogo"
                          src={`${window.location.origin}/assets/images/west-logo.png`}
                          alt=""
                        />
                        <h3 className="box-title m-b-20">Sign Up</h3>
                        <div className="form-group required">
                          <label className="control-label">First Name</label>
                          <Field
                            name="firstName"
                            type="text"
                            placeholder="Name"
                            className={`form-control${
                              errors.firstName && touched.firstName
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label"> Last Name</label>
                          <Field
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            className={`form-control${
                              errors.lastName && touched.lastName
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">
                            Select Organization
                          </label>
                          <Field
                            name="organization"
                            as="select"
                            className={`form-control${
                              errors.organization && touched.organization
                                ? " is-invalid"
                                : ""
                            }`}
                            placeholder="Select Organization"
                          >
                            <option value="">Select Organization</option>
                            {COMPANY_NAME.map((val) => (
                              <option value={val.value}>{val.name}</option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="organization"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Job Title</label>
                          <Field
                            name="jobTitle"
                            type="text"
                            placeholder="Job Title"
                            className={`form-control${
                              errors.jobTitle && touched.jobTitle
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="jobTitle"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Department</label>
                          <Field
                            name="department"
                            type="text"
                            placeholder="Department"
                            className={`form-control${
                              errors.department && touched.department
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="department"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Email</label>

                          <Field
                            name="email"
                            type="text"
                            placeholder="Email"
                            validate={validateEmail}
                            className={`form-control${
                              errors.email && touched.email ? " is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                          <span className="text-warning email-msg mt-1">
                            Note : Please use only Work Email to register,
                            please don't use any personal emails
                          </span>
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Password</label>
                          <Field
                            name="password"
                            placeholder="Password"
                            autoComplete="off"
                            render={({ field }) => (
                              <PasswordMask
                                inputStyles={{ border: "none" }}
                                buttonStyles={{
                                  top: "5px",
                                  backgroundColor: "transparent",
                                }}
                                showButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{
                                      color: "#ccc",
                                      backgroundColor: "transparent",
                                    }}
                                  />
                                }
                                hideButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{ backgroundColor: "transparent" }}
                                  />
                                }
                                onChange={(password) =>
                                  password === null
                                    ? setFieldValue("password", "")
                                    : setFieldValue("password", password)
                                }
                                {...field}
                                className={`form-control${
                                  errors.password && touched.password
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group required">
                          <label className="control-label">
                            Confirm Password
                          </label>
                          <Field
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            autoComplete="off"
                            render={({ field }) => (
                              <PasswordMask
                                inputStyles={{ border: "none" }}
                                buttonStyles={{
                                  top: "5px",
                                  backgroundColor: "transparent",
                                }}
                                showButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{
                                      color: "#ccc",
                                      backgroundColor: "transparent",
                                    }}
                                  />
                                }
                                hideButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{ backgroundColor: "transparent" }}
                                  />
                                }
                                onChange={(confirmPassword) =>
                                  confirmPassword === null
                                    ? setFieldValue("confirmPassword", "")
                                    : setFieldValue(
                                        "confirmPassword",
                                        confirmPassword,
                                      )
                                }
                                {...field}
                                className={`form-control${
                                  errors.confirmPassword &&
                                  touched.confirmPassword
                                    ? " is-invalid"
                                    : ""
                                }`}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        {/* <div className="form-group required">
                          <label className="control-label"> DOB</label>

                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            name="dob"
                            onChange={handleChange}
                            className={`form-control${
                              errors.dob && touched.dob ? " is-invalid" : ""
                            }`}
                            placeholder="MM/DD/YYYY"
                            guide={false}
                            type="text"
                            value={values.dob}
                            render={(ref, props) => (
                              <input
                                type="text"
                                label="Date of Birth"
                                name="dob"
                                ref={ref}
                                value={values.dob}
                                {...props}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="dob"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div> */}

                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                            >
                              Submit
                            </button>
                            {isSubmitting && (
                              <img
                                alt=""
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                              />
                            )}
                          </div>
                        </div>
                        <div className="pt-2 text-center">
                          <span>
                            By creating an account, you agree to WEST&apos;s{" "}
                            <a
                              target="_blank"
                              href="https://lms-west-of-windsor-prod.s3.ca-central-1.amazonaws.com/Conditions+of+Use.pdf"
                            >
                              Conditions of Use
                            </a>{" "}
                            and{" "}
                            <a
                              target="_blank"
                              href="https://lms-west-of-windsor-prod.s3.ca-central-1.amazonaws.com/Privacy+Notice.pdf"
                            >
                              Privacy Notice.
                            </a>
                          </span>
                        </div>
                        <div className="pt-2 text-center mb-3">
                          <span>
                            Already have an account?{" "}
                            <Link to="/login">Sign in</Link>
                          </span>
                        </div>
                        {status && (
                          <div className="alert alert-danger mt-2 mb-3">
                            {status}
                          </div>
                        )}
                        <div className="text-center">
                          <span>Powered By YQG Technologies Inc.</span>
                        </div>
                      </div>
                    </Form>
                  )}
                />
                <form className="form-horizontal" id="recoverform" action="#">
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <h3>Recover Password</h3>
                      <p className="text-muted">
                        Enter your Email and instructions will be sent to you!{" "}
                      </p>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <input
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="Email"
                      />{" "}
                    </div>
                  </div>
                  <div className="form-group text-center mb-0 m-t-20">
                    <div className="col-xs-12">
                      <button
                        className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                        type="submit"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} centered toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>
              Account Verification
            </ModalHeader>
            <ModalBody>
              <h3>Thanks for signing up!</h3>
              <p>
                You will need to verify your email address in order to access
                your account.
              </p>
              <p>
                An email has been sent to your mail address with instruction for
                the same.
              </p>
              <p>Thanks, LMS Team.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
