/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import Pagination from "../../shared/Pagination";
import { LIMIT } from "../constants/constants";
import { withRouter } from "react-router-dom";
import courseCategoryService from "../../_services/staff/courseCategory.service";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

const defaultCourseCategoryImage = "/assets/images/college-project.png";

class CourseCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      courseCategories: [],
      loading: true,
      page: 1,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    userService
      .getUserById(currentUser?.id)
      .then((data) => {
        data.Data && this.props.setUserData(data.Data);
      })
      .catch((error) => console.log("Error", error));

    this.getAllContentCategories();
    if (window.Websdk) {
      window.Websdk.q.push(["track_pageview", "course-categories"]);
    }
  }

  // eslint-disable-next-line react/sort-comp
  getAllContentCategories() {
    courseCategoryService
      .getAllCourseCategories()
      .then((data) => {
        console.log("data: ", data);
        const rowsArray = [];
        data &&
          data?.Data &&
          data?.Data?.Data?.forEach((item) => {
            rowsArray.push({
              _id: item._id,
              category: item.title,
              description: item.description,
              location: item.location,
              clickEvent: () => document.getElementById(item?._id).click(),
            });
          });

        const tableData = {
          columns: [
            // {
            //   label: "No.",
            //   field: "key",
            //   sort: "asc",
            //   width: 270,
            // },
            {
              label: "Course",
              field: "category",
              sort: "asc",
              width: 150,
            },
            {
              label: "Description",
              field: "description",
              sort: "asc",
              width: 150,
            },
            {
              label: "View",
              field: "view",
              sort: "disabled",
              width: 270,
            },
          ],
          rows: rowsArray,
        };
        this.setState({
          courseCategories: tableData,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Error", error);
      });
  }

  handlePageClick(e) {
    this.setState({ page: e.selected + 1 });
  }

  handleImageError = (event) => {
    event.target.src = defaultCourseCategoryImage;
  };

  render() {
    const { courseCategories } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        <div className="page-wrapper contentLibrary d-flex justify-content-center align-items-center">
          {this.state.loading ? (
            <Preloader />
          ) : (
            <>
              <div className="container-fluid">
                <div className="row page-titles">
                  <div className="col-md-5 col-8 align-self-center">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item cursor-pointer"
                        onClick={() => this.props.history.push("/")}
                      >
                        Home
                      </li>
                      <li className="breadcrumb-item active">Categories</li>
                    </ol>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="card">
                        <div className="card-body">
                          {/* <div className="d-flex justify-content-between"> */}
                          <div className="d-flex align-items-center tbl-head">
                            <i className="mdi mdi-view-dashboard mr-2" />
                            <h2 className="mb-0">Categories</h2>
                          </div>
                          {/* </div> */}
                          <hr />
                          <div className="row">
                            {courseCategories?.rows &&
                            courseCategories?.rows?.length !== 0 ? (
                              courseCategories?.rows?.map((item, index) => {
                                const { page } = this.state;
                                const start = (page - 1) * LIMIT;
                                const end = page * LIMIT;
                                return (
                                  <div
                                    className="col-xl-2 col-lg-3 col-md-4 col-sm-4"
                                    onClick={() =>
                                      this.props.history.push(
                                        `/course-category/${item?._id}`,
                                      )
                                    }
                                  >
                                    <div>
                                      <div className="resource-library-card rounded mb-4">
                                        <div className="card-image">
                                          <img
                                            src={
                                              item?.location
                                                ? item?.location
                                                : defaultCourseCategoryImage
                                            }
                                            alt="Card Image"
                                            onError={this.handleImageError}
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              width: "100%",
                                              height: "250px",
                                            }}
                                          />
                                        </div>
                                        <div className="resource-library-card-body">
                                          <div className="card-top">
                                            <div
                                              className="document-content"
                                              role="button"
                                              tabIndex="0"
                                            >
                                              <div className="d-flex flex-row justify-content-between">
                                                <h3 className="text-truncate">
                                                  {item.category}
                                                </h3>
                                                <div className="category-resume">
                                                  <img
                                                    src="/images/right-arrow.png"
                                                    alt="aero"
                                                  />
                                                </div>
                                              </div>
                                              <div className="course-description rounded pb-3">
                                                <p>{item.description}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div
                                className="d-flex flex-column justify-content-center align-items-center no-data w-100"
                                style={{
                                  minHeight: "20vh",
                                }}
                              >
                                <div className="text p-4">
                                  Thanks for Registering and for Verifying your
                                  account! You will be able to access the
                                  Training Modules within 24 hours.
                                </div>
                              </div>
                            )}
                          </div>
                          {courseCategories?.rows &&
                          courseCategories?.rows?.length !== 0 ? (
                            <Pagination
                              totalCount={courseCategories?.rows?.length}
                              currentPage={this.state.page - 1}
                              handlePageClick={this.handlePageClick}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterAdmin />
            </>
          )}
          {/* <HeaderAdmin />
        <Sidebaar /> */}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CourseCategory));
