/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { history, Role } from "../_helpers";
import { authenticationService } from "../_services";
import formService from "../_services/user/form.service";
import { setDepartmentsData } from "../redux-store/action";
import { FaBell } from "react-icons/fa6";
import { notificationService } from "../_services/user/notification.service";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdMarkChatRead } from "react-icons/md";
import { BiSolidArchiveIn } from "react-icons/bi";
import { timeAgo } from "../_helpers/_helperFunctions";
import { notificationStatus } from "../pages/constants/constants";
import swal from "sweetalert";
// import Avatar from "./assets/images/avatar.png";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setDepartments: (data) => {
      dispatch(setDepartmentsData(data));
    },
  };
}

class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentUserSubject: authenticationService.currentUser,
      currentDepartment: authenticationService.currentDepartmentValue,
      imageLoadError: false,
      notifications: [],
      count: 0,
    };

    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    this.state.currentUserSubject.subscribe({
      next: (v) => {
        this.setState({ currentUser: v });
      },
    });

    if (this.state.currentUser?.id) {
      this.getUnreadNotifications();
    }
  }

  getUnreadNotifications() {
    notificationService
      .getNotifications(this.state.currentUser?.id)
      .then((data) => {
        if (data?.Status) {
          const notifications = data?.Data?.Data;
          this.setState({ notifications });
        }
      })
      .catch((error) => {
        console.error("getNotifications error = ", error);
      });
  }

  updateNotifications(requestBody) {
    notificationService
      .updateStatus(requestBody)
      .then((data) => {
        if (data?.Data?.Status) {
          this.getUnreadNotifications();
        }
      })
      .catch((error) => {
        console.error("Notification updateStatus error: ", error);
      });
  }

  handleImageError = () => {
    this.setState({ imageLoadError: true });
  };

  toggleSidebar = (event) => {
    event.preventDefault();
    return window.jQuery("body").hasClass("mini-sidebar")
      ? (window.jQuery("body").trigger("resize"),
        window
          .jQuery(".scroll-sidebar, .slimScrollDiv")
          .css({ overflow: "hidden" })
          .parent()
          .css("overflow", "visible"),
        window.jQuery("body").removeClass("mini-sidebar"),
        window.jQuery(".navbar-brand span").show())
      : (window.jQuery("body").trigger("resize"),
        window
          .jQuery(".scroll-sidebar, .slimScrollDiv")
          .css("overflow-x", "visible")
          .parent()
          .css("overflow", "visible"),
        window.jQuery("body").addClass("mini-sidebar"),
        window.jQuery(".navbar-brand span").hide());
  };

  logout() {
    authenticationService.logout();
    history.push("/");
  }

  render() {
    const { currentUser, currentDepartment, notifications, count } = this.state;
    return (
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header header-color">
            <Link className="navbar-brand" to="/dashboard">
              <b style={{ verticalAlign: "middle" }}>
                <img
                  src={`${window.location.origin}/assets/images/WEST-logo.png`}
                  alt="logo"
                  className="dark-logo"
                />
              </b>
              <div style={{ display: "inline-block" }}>
                <img
                  src={`${window.location.origin}/assets/images/WEST-logo.png`}
                  alt="Logo text"
                  className="light-logo"
                />
              </div>
              <span>
                <img
                  src={`${window.location.origin}/assets/images/WEST-text-logo.png`}
                  alt="Logo text"
                  className="light-logo"
                />
              </span>
            </Link>
          </div>

          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                  href="#"
                  onClick={this.toggleSidebar}
                >
                  <i className="mdi mdi-menu" />
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link hidden-sm-down text-muted waves-effect waves-dark"
                  href="#"
                  onClick={this.toggleSidebar}
                >
                  <i className="ti-menu" />
                </a>{" "}
              </li>
              {currentUser?.role === Role.User && (
                <>
                  <li className="nav-item searching_li">
                    <Formik
                      initialValues={{
                        searchText: "",
                        fieldName: "firstName",
                      }}
                      validationSchema={Yup.object().shape({
                        searchText: Yup.string().required(
                          "Please enter a text",
                        ),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        setStatus();
                        const data = {
                          search_field: formData.fieldName,
                          search_value: formData.searchText,
                          allowForDepartments: currentDepartment,
                        };
                        formService.globleSearchForm(data).then(
                          (data) => {
                            data &&
                              data.Data?.length === 0 &&
                              NotificationManager.warning(
                                "No Record Found",
                                "",
                                5000,
                              );
                          },
                          (error) => {
                            setSubmitting(false);
                            setStatus(error);
                            NotificationManager.error(error, "", 5000);
                          },
                        );
                      }}
                    />
                  </li>{" "}
                </>
              )}
            </ul>
            <ul className="navbar-nav my-lg-0">
              <li className="nav-item dropdown text-center">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onMouseEnter={() => this.setState({ count: count + 1 })} // this is for calculate time when dropdown open
                >
                  <div className="d-flex flex-row justify-content-center">
                    <FaBell color="#fff" size={19} className="mt-2" />
                    <div>
                      <span className="badge bg-danger notifications">
                        {notifications.length}
                      </span>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li>
                      <div className="notification-label text-center">
                        Notifications{" "}
                        <span children="">({notifications.length})</span>
                      </div>
                    </li>
                    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                      {notifications.map((notification, index) => {
                        const { _id, title, message, createdAt } = notification;
                        return (
                          <div
                            key={_id + index}
                            onClick={() => {
                              const data = {
                                notificationId: _id,
                                status: notificationStatus.READ,
                              };
                              this.updateNotifications(data);
                            }}
                          >
                            <li role="separatowr" className="divider" />
                            <li>
                              <div className="notification-link waves-effect waves-dark py-0">
                                <div>
                                  <span className="notification-title">
                                    {title}
                                  </span>
                                  <span className="notification-text">
                                    {message}
                                  </span>
                                  <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div>
                                      <span className="notification-time">
                                        {timeAgo(createdAt)}
                                      </span>
                                    </div>
                                    {/* 
                                    <div className="mt-1">
                                      // Mark as read icon
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip
                                            placement="right"
                                            className="in tooltip-text"
                                            id="tooltip-right"
                                            style={{
                                              fontSize: "0.8rem",
                                              padding: "0",
                                            }}
                                          >
                                            Mark as Read
                                          </Tooltip>
                                        }
                                      >
                                        <MdMarkChatRead
                                          className="mr-2"
                                          onClick={() => {
                                            const data = {
                                              notificationId: _id,
                                              status: notificationStatus.READ,
                                            };
                                            this.updateNotifications(data);
                                          }}
                                        />
                                      </OverlayTrigger>

                                      // Mark as Archive icon
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip
                                            placement="right"
                                            className="in tooltip-text"
                                            id="tooltip-right"
                                            style={{
                                              fontSize: "0.8rem",
                                              padding: "0",
                                            }}
                                          >
                                            Mark as Archive
                                          </Tooltip>
                                        }
                                      >
                                        <BiSolidArchiveIn
                                          onClick={() => {
                                            const data = {
                                              notificationId: _id,
                                              status:
                                                notificationStatus.ARCHIVE,
                                            };
                                            this.updateNotifications(data);
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        );
                      })}
                    </div>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav my-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="mr-2">{`Welcome, ${currentUser?.firstName}`}</span>
                  <img
                    src={
                      this.state.imageLoadError
                        ? `${window.location.origin}/assets/images/avatar.png`
                        : currentUser?.profileImageUrl
                    }
                    alt="user"
                    className="profile-pic"
                    onError={this.handleImageError}
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li>
                      <div className="dw-user-box text-center">
                        <div className="u-img">
                          <img
                            src={
                              this.state.imageLoadError
                                ? `${window.location.origin}/assets/images/avatar.png`
                                : currentUser?.profileImageUrl
                            }
                            alt="user"
                            onError={this.handleImageError}
                          />
                        </div>
                        <div className="u-text mt-2">
                          <h4>{`${currentUser?.firstName} ${currentUser?.lastName}`}</h4>
                          <p className="text-muted">{currentUser?.email}</p>
                        </div>
                      </div>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <Link
                        to="/my-profile"
                        className="waves-effect waves-dark"
                      >
                        <i className="ti-user mr-1" /> My Profile
                      </Link>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <Link
                        to="/change-password"
                        className="waves-effect waves-dark"
                      >
                        <i className="ti-lock mr-1" />
                        Change Password
                      </Link>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <a onClick={this.logout}>
                        <i className="fa fa-power-off" /> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item dropdown d-flex align-items-center">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={`/assets/images/canada-flag.png`}
                    alt="flag-cn"
                    height={25}
                    width={20}
                    className="d-flex justify-content-center"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
