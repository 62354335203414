import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";
export const eventService = {
  getAllCurrentEvents,
  registerEvent,
  getAllRegisteredEvents,
  addToWishlist,
  getAllWishlistEvents,
  deleteRegisteredEvents,
  deleteWishlistedEvents,
  markAttended,
  saveWorkshopFeedback,
};

function getAllCurrentEvents(currentTime, userId, type = "workshop") {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/current/${currentTime}/${userId}?type=${type}`,
    requestOptions
  ).then(handleResponse);
}

function registerEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/events/register`, requestOptions).then(
    handleResponse
  );
}

function getAllRegisteredEvents(userId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/myevents/${userId}`,
    requestOptions
  ).then(handleResponse);
}

function addToWishlist(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/events/wishlist`, requestOptions).then(
    handleResponse
  );
}

function getAllWishlistEvents(userId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/wishlist/${userId}`,
    requestOptions
  ).then(handleResponse);
}

function deleteRegisteredEvents(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/register/delete`,
    requestOptions
  ).then(handleResponse);
}

function deleteWishlistedEvents(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/wishlist/delete`,
    requestOptions
  ).then(handleResponse);
}

function markAttended(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/mark-attended`,
    requestOptions
  ).then(handleResponse);
}

function saveWorkshopFeedback(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/events/workshop-feedback`,
    requestOptions
  ).then(handleResponse);
}
