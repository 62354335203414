import React, { Component } from "react";
import { connect } from "react-redux";

import Preloader from "../components/Preloader";

import { authenticationService } from "../_services";
import { userService } from "../_services/admin";

function mapStateToProps(state) {
  return {
    count: state.count,
  };
}

function mapDispatchToProps() {
  return {};
}

class AccountVerification extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/dashboard");
    }

    this.state = {
      isVerified: false,
    };
  }

  componentDidMount() {
    const scripts = ["/assets/js/custom.min.js"];
    const host = window.location.origin;
    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = host + t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });

    userService.verifyEmail(this.props.match.params.token).then((result) => {
      if (result.Status) {
        this.setState({ isVerified: true });
      }
    });
  }

  render() {
    return (
      <>
        {/* <Preloader /> */}
        <section id="wrapper">
          <div className="login-register">
            <div className="login-box card">
              <div className="card-body">
                {this.state.isVerified ? (
                  <div>
                    <h3>Your email has been successfully verified!</h3>
                    <p>Please login to access your account.</p>
                    <button
                      type="button"
                      className="btn btn-success btn-block"
                      onClick={() => this.props.history.push("/")}
                    >
                      Login
                    </button>
                  </div>
                ) : (
                  <h3>Please wait while we verify your account</h3>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountVerification);
